import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './login.css';

import { useSnackbar } from 'notistack';

import { withRouter, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import CONFIG from "config/environment";


import { BotonSuccess, Boton } from 'components/material-ui';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const CambiarContrasenaOlvidada = props => {
    let query = useQuery();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [contrasena, setContrasena] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [datos, setDatos] = useState(null);
    const [listo, setListo] = useState(false);


    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Olvidé mi contraseña';
        if (query.get("cuentaTipo") && query.get("usuario") && query.get("token")) {
            setDatos({
                cuentaTipo: query.get("cuentaTipo"),
                usuario: query.get("usuario"),
                token: query.get("token")
            });
        }
        setListo(true);
    }, []);
    const confirmar = () => {
        if (contrasena) {
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var options = {
                url: Api.route('p/cambiar-contrasena-con-token'),
                data: {
                    tipoCuentaId: 4,
                    usuario: datos.usuario,
                    token: datos.token,
                    contrasena: contrasena
                },
                method: 'POST'
            };
            Api.requestAxios(options, response => {
                Helper.log('cambiarContrasena response', response);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    enqueueSnackbar(response.data.msg, { variant: 'success' });
                    dispatch(AuthActions.setLoginForm(true));
                    const { history } = props;
                    if (history) history.push('/');
                }
            }, error => {
                Helper.log('error cambiarContrasena', error);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al enviar', { variant: 'error' });
            });
        }
    }
    const cancelar = () => {
        const { history } = props;
        if (history) history.push('/olvide-mi-contrasena');
    }
    return (
        <div id="pagina-cambiar-contrasena-olvidada" className="w3-block content-min-height w3-padding-32">
            {(listo && datos) ? <div className="w3-block">

                <div className="w3-block w3-center">
                    <p>Introduce tu contraseña nueva</p>
                    <FormControl variant="outlined" style={{ width: '320px' }}>
                        <InputLabel htmlFor="input-password">Contraseña*</InputLabel>
                        <OutlinedInput
                            id="input-password"
                            type={passwordVisible ? 'text' : 'password'}
                            value={contrasena}
                            onChange={e => setContrasena(e.target.value)}
                            name="contrasena"
                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="ver"
                                        onClick={() => setPasswordVisible((visible) => !visible)}
                                        onMouseDown={e => e.preventDefault()}
                                        edge="end"
                                    >
                                        {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={85}
                        />
                    </FormControl><br />
                    {contrasena ? <BotonSuccess onClick={() => confirmar()} variant="contained">Confirmar</BotonSuccess> : null}<br />
                    <Boton color="default" onClick={() => cancelar()} variant="contained">Cancelar</Boton>
                </div>
            </div> : null}
            {(listo && !datos) ? <div className="w3-block">
                <p>Datos incompletos</p>
                <Boton color="default" onClick={() => cancelar()} variant="contained">Solicitar nueva contraseña</Boton>
            </div> : null}

        </div>
    );
}
export default withRouter(CambiarContrasenaOlvidada);