// Initial State
const appInitialState = {
    actionsDisabled: false,
    loading: false,
    lastVisited: 'login',
    menuAbierto: false,
    itemsCarrito: {},
    paginaActual: null,
	areaCobertura: [],
    pedido: null,
    rand: null
};
// Reducers (Modifies The State And Returns A New State)
const appReducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            return {
                ...appInitialState
            };
        }
        case 'ACTIONS_DISABLED': {
            return {
                ...state,
                actionsDisabled: action.actionsDisabled,
            }
        }
        case 'LOADING': {
            return {
                ...state,
                loading: action.loading,
            }
        }
        case 'LAST_VISITED': {
            return {
                ...state,
                lastVisited: action.lastVisited,
            }
        }
        case 'MENU_PERFIL_ABIERTO': {
            return {
                ...state,
                menuPerfilAbierto: action.menuPerfilAbierto,
            }
        }
        case 'ITEMS_CARRITO': {
            return {
                ...state,
                itemsCarrito: action.itemsCarrito
            }
        }
        case 'PEDIDO': {
            return {
                ...state,
                pedido: action.pedido
            }
        }
        case 'PAGINA_ACTUAL': {
            return {
                ...state,
                paginaActual: action.paginaActual
            }
        }
		case 'AREA_COBERTURA': {
			return {
				...state,
				areaCobertura: action.areaCobertura
			}
		}
        case 'RAND': {
            return {
                ...state,
                rand: action.rand
            }
        }
        default: {
            return state;
        }
    }
};
// Exports
export { appReducer, appInitialState };