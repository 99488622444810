import React from 'react';
import { Helper } from 'utils';
import './grid-productos.css';
import { Api } from 'services';
import { withRouter, Link } from "react-router-dom";
const GridResponsiveProductos = (props) => {
    const items = props.items;
    return (
        <div className="w3-row">
            {items.map((item) => <div key={item.id} className="w3-col l3 m4 s12 bs-bbox w3-padding">
                <Link to={`/producto/${item.id}`} style={{ textDecoration: 'none' }}>
                    <div style={{ border: 'solid #dddddd 1px' }} className="bs-bbox w3-round">
                        <div className="grid-img-container"
                            style={{
                                backgroundImage: "url('" + Api.route(item.multimedia[0].url) + "')"
                            }}>
                        </div>
                        <div className="w3-block limit-text bs-bbox line-clamp-2" style={{ padding: '8px 16px 10px 16px' }}>
                            <div>
                            {item.nombre && <h4 className="w3-text-gray w3-center limit-text" style={{margin: 0}}>{item.nombre}</h4>}
                            </div>
                            
                        </div>
                        <div className="w3-block limit-text bs-bbox" style={{ padding: '0 16px 8px 16px' }}>
                            {item.precio && <h3 className="w3-text-theme w3-center" style={{margin: 0}}>{'₲ ' + Helper.numberUi(item.precio)}</h3>}
                        </div>
                    </div>

                </Link>
                <br />
            </div>)}
        </div>
    );
}
export default withRouter(GridResponsiveProductos);