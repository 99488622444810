import React from 'react'
import { Card, CardContent, CardMedia, CardActions, Button } from '@material-ui/core'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Helper } from 'utils'
import { Api } from 'services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles({
	card: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		padding: '5px 10px',
		border: '1px solid #eee',
		margin: '5px 0'
	},
	cardMedia: {
		height: 120,
		width: 120,
		flexShrink: 0
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flexGrow: 1,
		paddingBottom: '0 !important'
	},
	cardHeader: {
		fontWeight: 'bold',
		fontSize: 18,
		height: 48,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical'
	},
	cardPrice: {
		color: 'green',
		fontSize: '16px',
	},
	cardQuantity: {
		color: 'gray',
		fontSize: '14px',
		width: '120px'
	},
	cardTotal: {
		color: 'blue',
		fontSize: '16px',
		fontWeight: 'bold',
	},
	cardActions: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center'
	}
})

const IconButtonExtraSmall = withStyles({
	root: {
		height: 28,
		width: 28,
		margin: '0px',
		fontSize: '14px'
	}
})(IconButton);

const ProductoCard = (props) => {
	const { producto, precioKey, totalLabel, quitarDelCarrito = null, menos = null, mas = null, onChangeCantidad = null, actionsDisabled, noActions } = props

	const classes = useStyles()

	return (
		<div className={classes.card}>
			<div style={{ display: 'flex', paddingBottom: 12 }}>
				<CardMedia
					className={classes.cardMedia}
					image={Api.route(producto.multimedia[0].url)}
					title={producto.nombre}
				/>
				<CardContent className={classes.cardContent}>
					<div className={classes.cardHeader}>{producto.nombre}</div>
					<div className={classes.cardPrice}>
						{'₲ ' + Helper.numberUi(producto[precioKey])}
					</div>
					<div className={classes.cardTotal}>
						{totalLabel}: {'₲ ' + Helper.numberUi(producto.cantidad * producto[precioKey])}
					</div>
				</CardContent>
			</div>
			<CardActions className={noActions ? '' : classes.cardActions}>
				{noActions ? (
					<div><strong>Cantidad:</strong> {producto.cantidad}</div>
				) : (
					<>
						<div className={classes.cardQuantity}>
							<FormControl variant="outlined">
								<InputLabel></InputLabel>
								<OutlinedInput
									id={"input-cantidad-" + producto.id}
									type="text"
									fullWidth={true}
									value={producto.cantidad}
									onChange={(e) => onChangeCantidad(e, producto)}
									disabled={actionsDisabled ? true : false}
									startAdornment={
										<InputAdornment position="start">
											<IconButtonExtraSmall
												aria-label="Menos"
												onClick={() => menos(producto)}
												onMouseDown={e => e.preventDefault()}
												edge="start"
											>
												<FontAwesomeIcon icon={producto.cantidad > 1 ? faMinus : faTrash} size="xs" />
											</IconButtonExtraSmall>
										</InputAdornment>
									}
									endAdornment={
										<InputAdornment position="end">
											<IconButtonExtraSmall
												aria-label="Más"
												onClick={() => mas(producto)}
												onMouseDown={e => e.preventDefault()}
												edge="end"
											>
												<FontAwesomeIcon icon={faPlus} size="xs" />
											</IconButtonExtraSmall>
										</InputAdornment>
									}
									inputProps={{ style: { textAlign: 'center' } }}
								/>
							</FormControl>
						</div>
						<button
							onClick={() => quitarDelCarrito(producto.id)}
							className="w3-btn"
							style={{ border: '1px solid #ccc', borderRadius: 5, color: '#333', }}
						>
							<FontAwesomeIcon icon={faTrash} /> Eliminar
						</button>
					</>
				)}
			</CardActions >
		</div >
	)
}

export default ProductoCard
