// BASIC
export const PRIMARY = '#898aa1';
export const SECONDARY = '#767676';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

// ACTIONS
export const SUCCESS = '#28A745';
export const INFO = '##cff4fc';
export const WARNING = '#ffae00';
export const ALERT = '#cc4b37';
export const DEFAULT = '#E0E0E0';

// GRAYSCALE
export const GRAY_LIGHT = '#e6e6e6';
export const GRAY_MEDIUM = '#cacaca';
export const GRAY_DARK = '#8a8a8a';

// BRANDS
export const BRAND_FACEBOOK = '#3b5998';
export const BRAND_TWITTER = '#1da1f2';
export const BRAND_INSTAGRAM = '#c13584';

// THEME
// Utilizar los mismos valores que están en styles/w3-theme.css
export const THEME_L5 = '#f9f9ff';
export const THEME_L4 = '#ebebff';
export const THEME_L3 = '#d6d6ff';
export const THEME_L2 = '#c2c2ff';
export const THEME_L1 = '#adadff';
export const THEME = '#9999ff';
export const THEME_D1 = '#7070ff';
export const THEME_D2 = '#4747ff';
export const THEME_D3 = '#1f1fff';
export const THEME_D4 = '#0000f5';
export const THEME_D5 = '#0000cc';