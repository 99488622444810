import React from 'react'
import ProductoCard from './ProductoCard'
import ProductosTable from './ProductosTable';
import { useWindowSize } from 'hooks';

export default function ProductosList(props) {
	const { productos, precioKey = 'precio', totalLabel = 'Total', quitarDelCarrito, menos, mas, onChangeCantidad, actionsDisabled, noActions } = props
	// Usamos un custom hook para obtener el ancho de la pantalla[^4^][5]
	const windowSize = useWindowSize()

	// Si el ancho es menor que 600px, mostramos como tarjetas
	if (windowSize.width < 600) {
		return (
			<div className="w3-row">
				{Object.keys(productos).map((id) => (
					<ProductoCard
						key={id}
						producto={productos[id]}
						precioKey={precioKey}
						totalLabel={totalLabel}
						quitarDelCarrito={quitarDelCarrito}
						menos={menos}
						mas={mas}
						onChangeCantidad={onChangeCantidad}
						actionsDisabled={actionsDisabled}
						noActions={noActions}
					/>
				))}
			</div>
		);
	}

	// Si el ancho es mayor o igual que 600px, mostramos como tabla
	return (
		<ProductosTable
			productos={productos}
			precioKey={precioKey}
			totalLabel={totalLabel}
			quitarDelCarrito={quitarDelCarrito}
			menos={menos}
			mas={mas}
			onChangeCantidad={onChangeCantidad}
			actionsDisabled={actionsDisabled}
			noActions={noActions}
		/>
	);
}