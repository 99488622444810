import React from 'react'
import { Helper } from 'utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCircle, faEye, faCheck } from '@fortawesome/free-solid-svg-icons'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Colors } from 'styles';
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import { OPCIONES_CANTIDAD_PEDIDOS, tipoTextoMap } from 'config/pedidos'

const BootstrapInput = withStyles((theme) => ({
	input: {
		fontSize: 16,
		padding: '10px 26px 10px 12px',
	},
}))(InputBase)

const BotonAccion = withStyles({
	root: {
		margin: '4px'
	}
})(Button)

const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
}));

const ListaPedidos = (props) => {
	const classes = useStyles();
	const { pedidos, loading, onAccion, paginador, numResultadosDesde, onAccionPaginador } = props

	const opcionesDeAcuantos = OPCIONES_CANTIDAD_PEDIDOS

	const handleAccion = (cual, pedidoId) => {
		onAccion(cual, pedidoId)
	}

	const handleAccionPaginador = (cual, extra) => {
		onAccionPaginador(cual, extra)
	}

	const getEstado = (pedido) => {
		let status = {
			estado: 'Pendiente',
			color: '#666D92'
		}

		if (pedido.recibido) {
			status = {
				estado: 'Pendiente',
				color: '#666D92'
			}
		}
		if (pedido.procesando) {
			status = {
				estado: 'Procesando',
				color: '#2067FA'
			}
		}
		if (pedido.listo) {
			status = {
				estado: 'Listo',
				color: '#00C58D'
			}
		}
		if (pedido.enCamino) {
			status = {
				estado: 'En camino',
				color: '#43eb3d'
			}
		}
		if (pedido.entregado) {
			status = {
				estado: 'Entregado',
				color: '#55c433'
			}
		}
		if (pedido.cancelado) {
			status = {
				estado: 'Cancelado',
				color: '#FC5C63'
			}
		}
		return status;
	}

	return (
		<>
			{(pedidos.length === 0 && !loading) && (
				<div className="w3-block bs-bbox">
					<h3 className="w3-text-gray">No hay resultados</h3>
				</div>
			)}
			{pedidos.length > 0 ? (
				<div className="w3-block w3-margin-top">
					<table className="w3-table w3-border w3-bordered tabla w3-white">
						<thead className="w3-border">
							<tr>
								<th>Fecha</th>
								<th>Tipo</th>
								<th>Items</th>
								<th>Monto total</th>
								<th>Estado</th>
								<th></th>
							</tr>
						</thead>
						<tbody className="w3-border">
							{pedidos.map((pedido) => {
								

								return (
									<tr key={pedido.id}>
										<td>{Helper.datetimeToUiFormat(pedido.recibido)}</td>
										<td>
											<div className={`tag ${pedido.tipo}`}>
												{tipoTextoMap[pedido.tipo]}
											</div>
										</td>
										<td>{pedido.items.length}</td>
										<td>{Helper.numberUi(pedido.montoTotal)} Gs. {pedido.pagado && <FontAwesomeIcon icon={faCheck} color={Colors.SUCCESS} />}</td>
										<td style={{ width: '130px' }}>
											<FontAwesomeIcon icon={faCircle} color={getEstado(pedido).color} />
											<b> {getEstado(pedido).estado}</b>
										</td>
										<td className="btn-detalles-responsive w3-hide-medium w3-hide-large">
											<BotonAccion onClick={() => handleAccion('ver', pedido.id)} color="primary" variant="contained" size="small">
												<FontAwesomeIcon icon={faEye} />
											</BotonAccion>
										</td>
										<td className="btn-detalles w3-hide-small">
											<BotonAccion onClick={() => handleAccion('ver', pedido.id)} color="primary" variant="contained" size="small">Ver detalles</BotonAccion>
										</td>
									</tr>
								)
							}
							)}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="5" className="w3-small">
									<div style={{ width: '100%', maxWidth: '300px', float: 'right' }}>
										<div className="w3-cell w3-cell-middle"><span>Filas por página </span></div>
										<div className="w3-cell">
											{opcionesDeAcuantos.length > 0 ? <FormControl variant="outlined">
												<Select
													size="small"
													labelId="demo-simple-select-outlined-label"
													id="demo-simple-select-outlined"
													value={paginador.cuantos}
													onChange={(e) => handleAccionPaginador('cambioNumResultados', e.target.value)}
													input={<BootstrapInput />}>
													{opcionesDeAcuantos.map((option) => <MenuItem key={option} value={option}>{option}</MenuItem>)}
												</Select>
											</FormControl> : null}
										</div>
										<div className="w3-cell w3-cell-middle"><span>{numResultadosDesde + 1} - {((numResultadosDesde + paginador.cuantos) > paginador.numResultados) ? paginador.numResultados : numResultadosDesde + paginador.cuantos} de {paginador.numResultados} </span></div>
										<div className="w3-cell w3-cell-middle">
											<IconButton aria-label="Atrás" onClick={() => handleAccionPaginador('atras')} size="small" className={classes.margin} disabled={numResultadosDesde <= 1}>
												<FontAwesomeIcon icon={faChevronLeft} size="sm" />
											</IconButton>
											<IconButton aria-label="Siguiente" onClick={() => handleAccionPaginador('siguiente')} size="small" className={classes.margin} disabled={((numResultadosDesde + paginador.cuantos) >= paginador.numResultados)}>
												<FontAwesomeIcon icon={faChevronRight} size="sm" />
											</IconButton>
										</div>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			) : null}
		</>
	)
}

export default ListaPedidos