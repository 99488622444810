import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import CONFIG from "config/environment";
import './login.css';

import { useSnackbar } from 'notistack';

import { withRouter } from "react-router-dom";

import TextField from '@material-ui/core/TextField';

import { BotonSuccess, Boton } from 'components/material-ui';

const OlvideMiContrasena = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [email, setEmail] = useState('');


    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
		dispatch(AuthActions.setLoginForm(false));
        document.title = CONFIG.APP_NAME + ' - Olvidé mi contraseña';        
    }, []);
    const enviarCorreoDeRecuperacion = () => {
        if (email && Helper.validateEmail(email)) {
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var options = {
                url: Api.route('p/resetear-contrasena'),
                data: {
                    tipoCuentaId: 4,
                    correo: email
                },
                method: 'POST'
            };
            Api.requestAxios(options, response => {
                Helper.log('enviarCorreoDeRecuperacion response', response);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    enqueueSnackbar(response.data.msg, { variant: 'success' });
                }
            }, error => {
                Helper.log('error enviarCorreoDeRecuperacion', error);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al enviar', { variant: 'error' });
            });
        } else if (email && !Helper.validateEmail(email)) {
            enqueueSnackbar('Correo no válido', { variant: 'error' });
        }
    }
    const cancelar = () => {
        dispatch(AuthActions.setLoginForm(true));
        const { history } = props;
        if (history) history.push('/');
    }
    return (
        <div id="pagina-olvide-mi-contrasena" className="w3-block content-min-height w3-center w3-padding-32">
            <p>Introduce la dirección de correo electrónico con el que te registraste</p>
            <div className="w3-block w3-center">
                    <TextField required fullWidth margin="normal" size="small" variant="outlined" style={{ width: '320px' }}
                        id="input-email"
                        label="Email"
                        type="email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        disabled={(state.appReducer.actionsDisabled) ? true : false} />
                    <div className="w3-block w3-center">
                    {email ? <BotonSuccess onClick={() => enviarCorreoDeRecuperacion()} variant="contained">Enviar correo de recuperación</BotonSuccess> : null}
                        <Boton color="default" onClick={() => cancelar()} variant="contained">Cancelar</Boton>
                    </div>
            </div>
        </div>
    );
}
export default withRouter(OlvideMiContrasena);