import React, { useState, useContext, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Helper } from 'utils';
import { Api } from 'services';
import { Colors } from 'styles';
import { Logo } from 'assets/logos';
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';

import { useSnackbar } from 'notistack';
const LoginFormModal = props => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	const [usuario, setUsuario] = useState('');
	const [contrasena, setContrasena] = useState('');
	// const [errorUsuario, setErrorUsuario] = useState(false);
	// const [errorContrasena, setErrorContrasena] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [todoBien, setTodoBien] = useState(false);
	const [mensaje, setMensaje] = useState(null);
	useEffect(() => {
		dispatch(AppActions.setLoading(false));
		dispatch(AppActions.setActionsDisabled(false));
		if (state.authReducer.hash && state.authReducer.loggedIn) {
			if (!Helper.isEmptyObject(state.authReducer.usuario)) {
				// login(state.authReducer.hash);
			}
		} else {
			dispatch(AuthActions.setHash(null));
			dispatch(AuthActions.setUsuario({}));
		}
	}, []);
	useEffect(() => {
		setTodoBien((usuario && contrasena) ? true : false);
		// setErrorUsuario(false);
		// setErrorContrasena(false);
	}, [usuario, contrasena]);
	const login = (hash) => {
		dispatch(AuthActions.setLoginProvider('ingalca'));
		dispatch(AppActions.setActionsDisabled(true));
		dispatch(AuthActions.setHash(hash));
		dispatch(AppActions.setLoading(true));
		console.log('state en login = ', state);
		var options = {
			url: Api.route('p/login'),
			method: 'POST',
			data: {
				tipoCuentaId: 4
			},
			loginProvider: 'ingalca',
			hash: hash
		};
		Api.requestAxios(options, response => {
			Helper.log('login response', response);
			dispatch(AppActions.setActionsDisabled(false));
			dispatch(AppActions.setLoading(false));
			if (response.data.error) {
				dispatch(AuthActions.setHash(null));
				enqueueSnackbar(response.data.msg, { variant: 'error' });
				setMensaje(response.data.msg);
			} else {
				if (response.data.datos.init && response.data.datos.init.usuario) {
					dispatch(AuthActions.setHash(hash));
					dispatch(AuthActions.setLoggedIn(true));
					dispatch(AuthActions.setUsuario(response.data.datos.init.usuario));
					dispatch(AuthActions.setLoginForm(false));
					const { history } = props;
					if (history) history.push('/mi-perfil/mis-datos');
				}
			}
		}, error => {
			dispatch(AuthActions.setHash(null));
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			Helper.log('error Login', error);
		});
	};
	// const getUbicaciones = () => {
	//     dispatch(AppActions.setActionsDisabled(true));
	//     dispatch(AppActions.setLoading(true));
	//     var options = {
	//         url: Api.route('u/mis-ubicaciones'),
	//         method: 'GET'
	//     };
	//     Api.requestAxios(options, response => {
	//         Helper.log('getUbicaciones response', response);
	//         dispatch(AppActions.setActionsDisabled(false));
	//         dispatch(AppActions.setLoading(false));
	//         if (response.data.error) {
	//             dispatch(AuthActions.setHash(null));
	//             enqueueSnackbar(response.data.error, { variant: 'error' });
	//         } else {
	//             dispatch(AuthActions.setUsuario({ ...state.authReducer.usuario, ubicaciones: response.data.datos }));
	//         }
	//     }, error => {
	//         dispatch(AppActions.setLoading(false));
	//         dispatch(AppActions.setActionsDisabled(false));
	//         Helper.log('error getUbicaciones', error);
	//     });
	// };
	const handleSubmit = event => {
		event.preventDefault();
		let todoBien = true;
		if (usuario) {
			// setErrorUsuario(false);
		} else {
			todoBien = false;
			// setErrorUsuario(true);
		}
		if (contrasena) {
			// setErrorContrasena(false);
		} else {
			todoBien = false;
			// setErrorContrasena(true);
		}
		if (todoBien) {
			var hash = btoa(usuario + ':' + contrasena);
			login(hash);
		}
	}
	const cancelar = () => {
		dispatch(AuthActions.setLoginForm(false));
	}
	// const olvide = () => {
	//     const { history } = props;
	//     if (history) history.push('/olvide-mi-contrasena');
	// }
	const handleChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		if (name === 'usuario') {
			setUsuario(value);
		}
		if (name === 'contrasena') {
			setContrasena(value);
		}
		setMensaje(null);
	};
	return (
		<div className={state.authReducer.loginForm ? "w3-modal w3-show" : "w3-modal w3-hide"}>
			<div className="w3-modal-content w3-animate-zoom w3-round w3-round-large login-box">
				<div className="w3-padding w3-light-gray w3-round w3-round-large bs-bbox">
					<span onClick={() => cancelar()} className="w3-btn w3-light-gray w3-hover-theme w3-display-topright w3-round w3-round-large"><FontAwesomeIcon icon={faTimes} /></span>
					<span><FontAwesomeIcon icon={faUser} color={Colors.THEME} /> Autenticación</span>
				</div>
				<div className="w3-container w3-padding bs-bbox">
					<div className="w3-center w3-margin"><img src={Logo} alt="logo" className="w3-margin" style={{ width: '140px' }} /></div>
					<form onSubmit={handleSubmit}>
						<TextField required fullWidth margin="normal" variant="outlined"
							id="input-usuario"
							label="Usuario"
							type="text"
							name="usuario"
							value={usuario}
							onChange={handleChange}
							disabled={(state.appReducer.actionsDisabled) ? true : false} /><br /><br />
						<FormControl variant="outlined" style={{ width: '100%' }}>
							<InputLabel htmlFor="input-password">Contraseña*</InputLabel>
							<OutlinedInput
								id="input-password"
								type={passwordVisible ? 'text' : 'password'}
								value={contrasena}
								onChange={handleChange}
								name="contrasena"
								disabled={(state.appReducer.actionsDisabled) ? true : false}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => setPasswordVisible((visible) => !visible)}
											onMouseDown={e => e.preventDefault()}
											edge="end"
										>
											{passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
										</IconButton>
									</InputAdornment>
								}
								labelWidth={85}
							/>
						</FormControl><br /><br />

						{mensaje ? <Alert severity="error">{mensaje}</Alert> : null}
						<div className="w3-center">
							<Link className="w3-text-theme" to="/registro" style={{ textDecoration: 'none' }}>¿Todavía no tenés cuenta? Registrate</Link><br />
							<Link className="w3-text-theme" to="/olvide-mi-contrasena" style={{ textDecoration: 'none' }}>Olvidé mi contraseña</Link>
						</div>

						<p className="w3-center">
							<Button
								variant="contained"
								size="large"
								color="primary"
								type="submit"
								disabled={(state.appReducer.actionsDisabled || !todoBien) ? true : false}>
								ENTRAR
							</Button>
						</p>
					</form>
				</div>
			</div>
		</div>

	);
}
export default withRouter(LoginFormModal);