import CONFIG from "config/environment";
// Imports: Actions
import * as AppActions from './appActions';
import * as AuthActions from './authActions';
import * as PerfilActions from './perfilActions';
// Imports: Dependencies
import combineReducers from 'react-combine-reducers';
import createPersistedReducer from 'use-persisted-reducer';
// Imports: Reducers
import { appReducer, appInitialState } from './appReducer';
import { authReducer, authInitialState } from './authReducer';
import { perfilReducer, perfilInitialState } from './perfilReducer';
// Root Reducer
const [rootReducerCombined, initialStateCombined] = combineReducers({
    appReducer: [ appReducer, appInitialState ],
    authReducer: [ authReducer, authInitialState],
    perfilReducer: [ perfilReducer, perfilInitialState ]
});
const usePersistedReducer = createPersistedReducer(CONFIG.ID);
const useStore = () => {
    const [state, dispatch] = usePersistedReducer(rootReducerCombined, initialStateCombined);
    return { state, dispatch };
}
// Exports
export { useStore, AppActions, AuthActions, PerfilActions };