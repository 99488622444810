import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { Colors } from 'styles'

export default function Marker() {
	return (
		<FontAwesomeIcon
			icon={faMapMarkerAlt}
			color={Colors.THEME}
			size="3x"
			style={{
				position: "absolute",
				transform: "translate(-50%, -100%)"
			}}
		/>
	)
}