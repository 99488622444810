import CONFIG from "config/environment";
const Helper = {
    diaMesAnhoSeparador: function (date, separador) {
        return [this.dosDigitos(date.getDate()), this.dosDigitos(date.getMonth() + 1), date.getFullYear()].join(separador);
    },
    dosDigitos: function (numero) {
        if (numero.toString().length === 1) {
            return "0" + numero;
        }
        return numero;
    },
    getRandomNum: function (minimo, maximo) {
        return Math.floor(Math.random() * ((maximo + 1) - minimo) + minimo);
    },
    dateToServerFormat: function (date) {
        return date.toISOString().split('T')[0];
    },
    dateToUiFormat: function (severFormat) {
        let fechaArray = severFormat.split("-");
        let date = new Date(fechaArray[0], fechaArray[1] - 1, fechaArray[2]);
        return this.diaMesAnhoSeparador(date, '/');
    },
	getMonthName(index) {
		var months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
		var monthName = months[index];
	
		return monthName;
	},
	datetimeToUiFormat(datetimeStr) {
		const datetimeParts = datetimeStr.split(' ')
		const dateStr = datetimeParts[0]
		const timeStr = datetimeParts[1]
		const date = new Date(dateStr.split('-').join('/'))
		let day = date.getDate()
	
		if (day < 10) {
			day = '0' + day
		}
	
		const year = date.getFullYear()
	
		const str = `${day} de ${this.getMonthName(date.getMonth())}, ${year} a las ${timeStr.substring(0, 5)}`
	
		return str
	},
    stringDateToServerFormat: function (dateString) {
        if (dateString.search("/")) {
            return dateString.split("/").reverse().join('-');
        }
        if (dateString.search("-")) {
            return dateString.split("-").reverse().join('-');
        }
    },
    serverFormatToDate: function (serverFormat) {
        let fechaArrayAux = serverFormat.split(" ");
        let fechaArray = fechaArrayAux[0].split("-");
        let horaArray = fechaArrayAux[1].split(":");
        let date = new Date(fechaArray[0], fechaArray[1] - 1, fechaArray[2]);
        date.setHours(parseInt(horaArray[0]));
        date.setMinutes(parseInt(horaArray[1]));
        date.setSeconds(parseInt(horaArray[2]));
        return date;
    },
    serverToDDMMYYYYhhmm: function (severFormat) {
        let fechaArrayAux = severFormat.split(" ");
        let fechaArray = fechaArrayAux[0].split("-");
        let horaArray = fechaArrayAux[1].split(":");
        let date = new Date(fechaArray[0], fechaArray[1] - 1, fechaArray[2]);
        return this.diaMesAnhoSeparador(date, '/') + ' ' + horaArray[0] + ':' + horaArray[1];
    },
    serverToDDMMYYYY: function (severFormat) {
        let fechaArrayAux = severFormat.split(" ");
        let fechaArray = fechaArrayAux[0].split("-");
        let date = new Date(fechaArray[0], fechaArray[1] - 1, fechaArray[2]);
        return this.diaMesAnhoSeparador(date, '/');
    },
    getFechaServer: function (severFormat) {
        let fecha = null;
        if (severFormat) {
            fecha = severFormat.split(" ")[0];
        }
        return fecha;
    },
    isEmptyObject: function (obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }
        return true;
    },
    getObjectFromArray: function (arrayObjects, key, value) {
        function findObject(object) {
            return object[key] === value;
        }
        return arrayObjects.filter(findObject)[0];
    },
    getObjectsFromArray: function (arrayObjects, key, value) {
        function findObject(object) {
            return object[key] === value;
        }
        return arrayObjects.filter(findObject);
    },
    arrayObjectsToArraySimple: function (arrayObjects, key) {
        let arraySimple = [];
        for (var i in arrayObjects) {
            arraySimple.push(arrayObjects[i][key]);
        }
        return arraySimple;
    },
    haveSameElements: function (array1, array2) {
        let same = true;
        if (array1.length === array2.length) {
            for (var i in array1) {
                if (array2.indexOf(array1[i]) === -1) {
                    same = false;
                }
            }
        } else {
            same = false;
        }
        return same;
    },
    momentValido: function (fechaMoment) {
        if ((typeof fechaMoment !== 'undefined') && (fechaMoment !== null) && (fechaMoment !== '') && (typeof fechaMoment !== 'boolean')) {
            return true;
        } else {
            return false;
        }
    },
    momentToYYYYMMDD: function (fechaMoment) {
        let yyyymmdd = null;
        if ((typeof fechaMoment !== 'undefined') && (fechaMoment !== null) && (fechaMoment !== '') && (typeof fechaMoment !== 'boolean')) {
            if (fechaMoment.format('YYYY-MM-DD')) {
                yyyymmdd = fechaMoment.format('YYYY-MM-DD');
            }
        }
        return yyyymmdd;
    },
    capitalize: function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    },
    stringToNumUiFormat: function (str) {
        let num = Number(str);
        return num.toLocaleString('ES-es');
    },
    getUrlParameter: function getUrlParameter(sParam) {
        let sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    },
    log: function (key, value) {
        if (CONFIG.LOGS) {
            console.log(key, value);
        }
    },
    validateEmail: function (mail) {
        if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return (true);
        }
        return (false);
    },
    numberUi: function (string, lang) {
        string = Number(string);
        string = string.toString();
        return string.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    },
    extraerTel: function (string) {
        return string.replace(/ |-|\(|\)/gi, "");
    },
	// recibe un string con un número de teléfono en el formato proveído por extraerTel
	agregarEspaciosTel: function(string) {
		let newStr = string.split('')
		newStr.splice(4, 0, ' ')
		newStr.splice(8, 0, ' ')
		
		return newStr
	},
    getGeoData: function (json) {
        let datosGeo = {
            direccion: null,
            barrio: null,
            ciudad: null,
            departamento: null,
            pais: null
        };
        if (json && json.results && json.results.length > 0) {
            for (var i in json.results) {
                if (json.results[i].types[0] === "route") {
                    datosGeo.calle = json.results[i]['formatted_address'].split(",")[0];
                }
                if (json.results[i].types[0] === "street_address") {
                    datosGeo.direccion = json.results[i]['formatted_address'].split(",")[0];
                }
                if (json.results[i].types[0] === "neighborhood") {
                    datosGeo.barrio = json.results[i]['formatted_address'].split(",")[0];
                }
                if (json.results[i].types[0] === "locality") {
                    datosGeo.ciudad = json.results[i]['formatted_address'].split(",")[0];
                }
                if (json.results[i].types[0] === "administrative_area_level_1") {
                    datosGeo.departamento = json.results[i]['formatted_address'].split(",")[0];
                }
                if (json.results[i].types[0] === "country") {
                    datosGeo.pais = json.results[i]['formatted_address'].split(",")[0];
                }
            }
            if (!datosGeo.direccion) {
                if (datosGeo.calle) {
                    datosGeo.direccion = datosGeo.calle;
                } else if (datosGeo.ciudad) {
                    datosGeo.direccion = datosGeo.ciudad;
                }
            }
        }
        return datosGeo;
    }
};
export default Helper;