import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './supportBubble.css'


const SupportBubble = (props) => {
	const { number } = props
	
	return (
		<a
			href={`https://wa.me/${number}`}
			className={'bubble'}
			target="_blank"
			rel="noopener noreferrer"
		>
			<FontAwesomeIcon icon={faWhatsapp} className={'icon'}/>
		</a>
	)
}

export default SupportBubble