import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Helper } from 'utils';
import { Api } from 'services';
import CONFIG from "config/environment";
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import { ENTREGA_TIPO_ENVIO, ENTREGA_TIPO_ENVIO_INTERIOR, ENTREGA_TIPO_RETIRO_TIENDA, isConBancard, isConSeleccionPuntoEntrega } from 'config/pedidos';
import './compras.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { Colors } from 'styles';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from "react-google-recaptcha";
import GoogleMapReact from 'google-map-react';
import BancardModal from './BancardModal';
import ProductosList from 'components/producto/ProductosList';

const Boton = withStyles({
	root: {
		background: `${Colors.THEME}`,
		borderRadius: 3,
		border: 0,
		color: 'white',
		'&:hover': {
			background: `${Colors.THEME_D4}`,
		}
	},
	label: {
		textTransform: 'uppercase',
	}
})(Button);

const FinalizarCompra = props => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	const [moneda, setMoneda] = useState('₲');
	const [usuario, setUsuario] = useState({
		email: '',
		contrasena: ''
	});
	const [cliente, setCliente] = useState({
		abm: 'nuevo',
		usuario: '',
		nombre: '',
		apellido: '',
		email: '',
		telefono: '',
		ruc: '',
		contrasena: '',
		razonSocial: ''
	});
	const [formLoginVisible, setFormLoginVisible] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [passwordVisibleLogin, setPasswordVisibleLogin] = useState(false);
	const [erroresLogin, setErroresLogin] = useState({});
	const [errores, setErrores] = useState({});
	const [recaptchaResponse, setRecaptchaResponse] = useState(null);
	const [bancardProcessId, setBancardProcessId] = useState(null)

	const isLoggedIn = state.authReducer?.usuario?.id !== undefined
	const conPuntoEntrega = isConSeleccionPuntoEntrega(state?.appReducer?.pedido?.tipo)

	let subtotal = 0;
	for (var i in state.appReducer.itemsCarrito) {
		subtotal = subtotal + (state.appReducer.itemsCarrito[i].precio * state.appReducer.itemsCarrito[i].cantidad);
	}
	let total = subtotal;
	if (conPuntoEntrega) {
		total += state.appReducer.pedido.envio.precio;
	}


	useEffect(() => {
		stopLoading()
		document.title = CONFIG.APP_NAME + ' - Finalizar compra';
		if (state.authReducer.hash && state.authReducer.loggedIn) {
			if (!Helper.isEmptyObject(state.authReducer.usuario)) {
				// login(state.authReducer.hash);
			}
		} else {
			dispatch(AuthActions.setHash(null));
			dispatch(AuthActions.setUsuario(null));
		}
		dispatch(AuthActions.setLoginProvider('ingalca'));
		if (isLoggedIn) {
			let datos = {
				id: state.authReducer.usuario.id,
				usuario: state.authReducer.usuario.usuario ? state.authReducer.usuario.usuario : '',
				nombre: state.authReducer.usuario.nombre ? state.authReducer.usuario.nombre : '',
				apellido: state.authReducer.usuario.apellido ? state.authReducer.usuario.apellido : '',
				email: state.authReducer.usuario.email ? state.authReducer.usuario.email : '',
				telefono: state.authReducer.usuario.telefono ? state.authReducer.usuario.telefono : '',
				ruc: state.authReducer.usuario.ruc ? state.authReducer.usuario.ruc : '',
				razonSocial: (state.authReducer.usuario.nombre && state.authReducer.usuario.apellido) ? state.authReducer.usuario.nombre + ' ' + state.authReducer.usuario.apellido : '',
			}
			setCliente(datos);
		}
	}, []);
	const Marker = () => <FontAwesomeIcon icon={faMapMarkerAlt} color={Colors.THEME} size="3x" style={{
		position: "absolute",
		transform: "translate(-50%, -100%)"
	}} />;
	const handleChangeLogin = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		setUsuario({ ...usuario, [name]: value });
		setErroresLogin({ ...erroresLogin, [name]: false });
	};
	const handleChange = (event) => {
		if (isLoggedIn) {
			const target = event.target;
			const value = target.type === 'checkbox' ? target.checked : target.value;
			const name = target.name;
			if (name === 'ruc' || name === 'razonSocial') {
				setCliente({ ...cliente, [name]: value });
				setErrores({ ...errores, [name]: false });
			}
		} else if (!isLoggedIn) {
			const target = event.target;
			const value = target.type === 'checkbox' ? target.checked : target.value;
			const name = target.name;
			setCliente({ ...cliente, [name]: value });
			setErrores({ ...errores, [name]: false });
		}
	};

	const irA = (path) => {
		const { history } = props;
		if (history) history.push(path);
	};
	const login = (hash) => {
		dispatch(AuthActions.setLoginProvider('ingalca'));
		dispatch(AuthActions.setHash(hash));

		var options = {
			url: Api.route('p/login'),
			method: 'POST',
			loginProvider: 'ingalca',
			hash: hash,
			data: {
				tipoCuentaId: 4
			},
		};
		Api.requestAxios(options, response => {
			Helper.log('login response', response);
			stopLoading()
			if (response.data.error) {
				dispatch(AuthActions.setHash(null));
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				if (response.data.datos.init && response.data.datos.init.usuario) {
					dispatch(AuthActions.setHash(hash));
					dispatch(AuthActions.setLoggedIn(true));
					dispatch(AuthActions.setUsuario(response.data.datos.init.usuario));
					setFormLoginVisible(false);
					const user = response.data.datos.init.usuario;
					let datos = {
						id: user.id,
						usuario: user.usuario ? user.usuario : '',
						nombre: user.nombre ? user.nombre : '',
						apellido: user.apellido ? user.apellido : '',
						email: user.email ? user.email : '',
						telefono: user.telefono ? user.telefono : '',
						ruc: user.ruc ? user.ruc : '',
						razonSocial: (user.nombre && user.apellido) ? user.nombre + ' ' + user.apellido : '',
					}
					setCliente(datos);
					setErrores({});
				}
			}
		}, error => {
			dispatch(AuthActions.setHash(null));
			stopLoading()
			Helper.log('error Login', error);
		});
	};
	const onClickLogin = () => {
		// event.preventDefault();
		let todoBien = true;
		if (usuario.email) {
			setErroresLogin({ ...erroresLogin, email: false });
		} else {
			todoBien = false;
			setErroresLogin({ ...erroresLogin, email: true });
		}
		if (usuario.contrasena) {
			setErroresLogin({ ...erroresLogin, contrasena: false });
		} else {
			todoBien = false;
			setErroresLogin({ ...erroresLogin, contrasena: true });
		}
		if (todoBien) {
			var hash = btoa(usuario.email + ':' + usuario.contrasena);
			login(hash);
		}
	}

	const realizarPedido = () => {
		let todoBien = true;
		let data = {};
		let errors = {};
		if (isLoggedIn) {
			const { ruc, razonSocial } = cliente;
			let datos = {};
			if (razonSocial) {
				datos.razonSocial = razonSocial;
			} else {
				// errors.razonSocial = true;
				// todoBien = false;
			}
			if (ruc) {
				datos.ruc = ruc;
			} else {
				// errores.ruc = true;
				// todoBien = false;
			}
			if (razonSocial || ruc) {
				datos.datosFacturacion = {};
				if (razonSocial) {
					datos.datosFacturacion.razonSocial = razonSocial;
				}
				if (ruc) {
					datos.datosFacturacion.ruc = ruc;
				}
			}
			finalizar(datos);
		} else {
			const { nombre, apellido, razonSocial, ruc, telefono, email, contrasena } = cliente;
			if (nombre) {
				data.nombre = nombre;
			} else {
				errors.nombre = true;
				todoBien = false;
			}
			if (apellido) {
				data.apellido = apellido;
			} else {
				errors.apellido = true;
				todoBien = false;
			}
			if (razonSocial) {
				data.razonSocial = razonSocial;
			} else {
				// errors.razonSocial = true;
				// todoBien = false;
			}
			if (ruc) {
				data.ruc = ruc;
			} else {
				// errores.ruc = true;
				// todoBien = false;
			}
			if (telefono) {
				data.telefono = telefono;
			} else {
				errors.telefono = true;
				todoBien = false;
			}
			if (email) {
				data.email = email;
				data.usuario = email;
			} else {
				errors.email = true;
				todoBien = false;
			}
			if (contrasena) {
				data.contrasena = contrasena;
			} else {
				errors.contrasena = true;
				todoBien = false;
			}
			if (recaptchaResponse) {
				data.recaptchaResponse = recaptchaResponse;
			} else {
				errors.recaptchaResponse = true;
				todoBien = false;
			}
			setErrores({ ...errores, ...errors });

			if (todoBien) {
				data.tipoUsuarioId = 4;
				dispatch(AuthActions.setLoginProvider('ingalca'));
				startLoading()
				var options = {
					url: Api.route('p/nuevo-usuario'),
					method: 'POST',
					data: data,
					loginProvider: 'ingalca'
				};
				Api.requestAxios(options, response => {
					Helper.log('registro response', response);
					stopLoading()
					if (response.data.error) {
						enqueueSnackbar(response.data.msg, { variant: 'error' });
					} else {
						var hash = btoa(email + ':' + contrasena);
						dispatch(AuthActions.setHash(hash));
						dispatch(AuthActions.setLoggedIn(true));
						dispatch(AuthActions.setUsuario(response.data.datos.usuario));
						let datos = { hash: hash };
						if (razonSocial || ruc) {
							datos.datosFacturacion = {};
							if (razonSocial) {
								datos.datosFacturacion.razonSocial = razonSocial;
							}
							if (ruc) {
								datos.datosFacturacion.ruc = ruc;
							}
						}
						finalizar(datos);
					}
				}, error => {
					dispatch(AuthActions.setHash(null));
					stopLoading()
					Helper.log('error registro', error);
				});
			}
		}
	};

	const pagarConBancard = (pagoId) => {
		const options = {
			url: Api.route('u/inicializar-pago'),
			method: 'POST',
			data: { pagoId },
			loginProvider: 'ingalca'
		}

		Api.requestAxios(options, response => {
			Helper.log('Bancard response', response)
			if (response.data.error) {
				const errores = JSON.parse(response.data.msg)

				let errorJsx = <div></div>
				if (errores.length === 1) {
					errorJsx = <div><strong>{errores[0].key}</strong>: {errores[0].dsc}</div>
				} else {
					errorJsx = <div>
						<div><strong>Ocurrieron los siguientes errores</strong></div>
						<ul>
							{errores.map(error => (
								<li><strong>{error.key}</strong>: {error.dsc}</li>
							))}
						</ul>
					</div>

				}

				enqueueSnackbar(errorJsx, { variant: 'error' })
			} else {
				setBancardProcessId(response.data.datos.processId)
			}
		}, error => {
			Helper.log('error nuevoPedido', error)
		});
	}

	const handleBancardResponse = (bancardResponse) => {
		console.log('bancardResponse', bancardResponse)
		if (bancardResponse.message === 'payment_success') {
			pedidoSuccess(bancardResponse.return_url) // si en el server return_url se dejó en blanco, solo viene el número de pedido
		} else {
			let errorMsg = bancardResponse.message
			if (bancardResponse.message === 'payment_fail') {
				errorMsg = 'Error en el pago. Favor contactarse con el emisor de su tarjeta para más detalles'
			}

			enqueueSnackbar(errorMsg, { variant: 'error' })
			setBancardProcessId(null)
		}

		stopLoading()
	}

	const handleCloseBancardModal = () => {
		setBancardProcessId(null)
	}

	const pagarEnLocal = (pagoId) => {
		const options = {
			url: Api.route('u/pagar-en-local'),
			method: 'POST',
			data: { pagoId },
			loginProvider: 'ingalca'
		}

		Api.requestAxios(options, response => {
			Helper.log('Pagar en local response', response)
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' })
			} else {
				pedidoSuccess(response.data.datos.pedidoId)
			}
		}, error => {
			stopLoading()
			Helper.log('error Pagar en local', error)
		})
	}

	const pagarContraEntrega = (pagoId) => {
		const options = {
			url: Api.route('u/pagar-envio-contra-entrega'),
			method: 'POST',
			data: { pagoId },
			loginProvider: 'ingalca'
		}

		Api.requestAxios(options, response => {
			Helper.log('Pagar contra entrega response', response)
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' })
			} else {
				pedidoSuccess(response.data.datos.pedidoId)
			}
		}, error => {
			stopLoading()
			Helper.log('error Pagar contra entrega', error)
		})
	}

	const pagarConEnvioInterior = (pagoId) => {
		const options = {
			url: Api.route('u/pagar-con-envio-al-interior'),
			method: 'POST',
			data: { pagoId },
			loginProvider: 'ingalca'
		}

		Api.requestAxios(options, response => {
			Helper.log('Pagar contra entrega response', response)
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' })
			} else {
				pedidoSuccess(response.data.datos.pedidoId)
			}
		}, error => {
			stopLoading()
			Helper.log('error Pagar contra entrega', error)
		})
	}

	const nuevoPedido = (pedido) => {
		startLoading()
		const options = {
			url: Api.route('u/nuevo-pedido'),
			method: 'POST',
			data: pedido,
			loginProvider: 'ingalca'
		}

		if (pedido.hash) {
			options.hash = pedido.hash;
		}

		Api.requestAxios(options, response => {
			Helper.log('nuevoPedido response', response)
			// No se saca el disabled ni loading porque se viene una siguiente petición al server
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' })
				stopLoading()
			} else {
				if (isConBancard(pedido.tipo)) {
					pagarConBancard(response.data.datos.pagoId)
				} else {
					switch(pedido.tipo) {
						case ENTREGA_TIPO_RETIRO_TIENDA:
							pagarEnLocal(response.data.datos.pagoId)
							break
						case ENTREGA_TIPO_ENVIO:
							pagarContraEntrega(response.data.datos.pagoId)
							break
						case ENTREGA_TIPO_ENVIO_INTERIOR:
							pagarConEnvioInterior(response.data.datos.pagoId)
							break
					}
				}
			}
		}, error => {
			stopLoading()
			Helper.log('error nuevoPedido', error)
		});
	}

	const finalizar = (datos) => {
		let pedido = {
			via: 'web'
		}

		if (datos.datosFacturacion) {
			pedido.datosFacturacion = datos.datosFacturacion
		}

		if (state.appReducer.pedido.puntoEntregaId) {
			pedido.puntoEntregaId = state.appReducer.pedido.puntoEntregaId
		}
		if (state.appReducer.pedido.items) {
			pedido.items = state.appReducer.pedido.items
		}
		if (state.appReducer.pedido.tipo) {
			pedido.tipo = state.appReducer.pedido.tipo
		}

		if (isConBancard(state.appReducer.pedido.tipo)) {
			pedido.esperarPagoParaRecibir = true
		}

		if (pedido.items && pedido.items.length > 0) {
			nuevoPedido(pedido)
		}
	}

	const pedidoSuccess = (pedidoId) => {
		dispatch(AppActions.setPedido(null))
		dispatch(AppActions.setItemsCarrito(null))

		enqueueSnackbar('¡Pedido realizado exitosamente!', { variant: 'success' });
		const { history } = props
		if (history) {
			history.push('mi-perfil/pedido?pedidoId=' + pedidoId)
		}
	}

	const startLoading = () => {
		dispatch(AppActions.setLoading(true))
		dispatch(AppActions.setActionsDisabled(true))
	}

	const stopLoading = () => {
		dispatch(AppActions.setLoading(false))
		dispatch(AppActions.setActionsDisabled(false))
	}

	const onChangeRecaptcha = (value) => {
		setRecaptchaResponse(value);
	}

	const onExpiredRecaptcha = () => {
		setRecaptchaResponse(null);
	}

	return (
		<div id="pagina-finalizar-compra" className="w3-block content-min-height">
			<div className="w3-content">
				<div className="w3-block w3-padding">
					<h2 className="w3-text-gray" style={{ margin: '0px' }}>Finalizar compra</h2>
				</div>
				{(!state.authReducer.usuario) ? <div className="w3-row">
					<div className="w3-col 12 w3-padding bs-bbox">
						<div className="w3-round w3-cell" style={{ backgroundColor: '#3D9CD2', paddingLeft: '16px', paddingRight: '16px' }}>
							{!formLoginVisible ? <p className="w3-text-white">
								<span>¿Ya eres cliente?</span> <a href="" onClick={(e) => {
									e.preventDefault();
									setFormLoginVisible(true);
								}}>Hacé clic aquí para iniciar sesión</a>
							</p> : null}
							{formLoginVisible ? <p className="w3-text-white">
								<span>¿No sos cliente?</span> <a href="" onClick={(e) => {
									e.preventDefault();
									setFormLoginVisible(false);
								}}>Seguir en la sección detalles de facturación</a>
							</p> : null}
						</div>
					</div>
				</div> : null}
				{formLoginVisible ? <div className="w3-row w3-animate-zoom">
					<div className="w3-col l7 w3-padding bs-bbox" style={{ backgroundColor: '#F4F4F6' }}>
						<div className="w3-row">
							<div className="w3-col l12">
								<h4 className="w3-text-gray" style={{ margin: 0 }}>Si ya eres cliente, por favor, rellena tus datos a continuación. Si eres un nuevo cliente, continúa en la sección de facturación.</h4>
							</div>
							<div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
								<TextField required fullWidth margin="normal" variant="outlined"
									error={erroresLogin.email}
									name="email"
									type="text"
									label="Email"
									value={usuario.email}
									onChange={handleChangeLogin}
									disabled={state.appReducer.actionsDisabled} /><br />
							</div>
							<div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
								<FormControl variant="outlined" style={{ width: '100%', marginTop: '16px' }}>
									<InputLabel htmlFor="input-password-login">Contraseña *</InputLabel>
									<OutlinedInput
										error={erroresLogin.contrasena}
										id="input-password-login"
										type={passwordVisibleLogin ? 'text' : 'password'}
										value={usuario.contrasena}
										onChange={handleChangeLogin}
										name="contrasena"
										disabled={(state.appReducer.actionsDisabled) ? true : false}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setPasswordVisibleLogin((visible) => !visible)}
													onMouseDown={e => e.preventDefault()}
													edge="end"
												>
													{passwordVisibleLogin ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
												</IconButton>
											</InputAdornment>
										}
										labelWidth={95}
									/>
								</FormControl><br /><br />
							</div>
							<div className="w3-col l12">
								<Boton onClick={() => onClickLogin()}>Iniciar sesión</Boton>
							</div>
						</div>
					</div>

				</div> : null}
				{!Helper.isEmptyObject(state.appReducer.itemsCarrito) ? <div className="w3-row w3-margin-top">
					<div className="w3-col l5 s12 w3-padding bs-bbox">
						<div className="w3-row">
							<div className="w3-col l12">
								<h3 className="w3-text-gray" style={{ margin: 0 }}>Detalles de facturación</h3>
							</div>
							<div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
								<TextField required fullWidth margin="normal" variant="outlined"
									error={errores.nombre}
									name="nombre"
									type="text"
									label="Nombre"
									value={cliente.nombre}
									onChange={handleChange}
									disabled={state.appReducer.actionsDisabled || isLoggedIn} /><br /><br />
							</div>
							<div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
								<TextField required fullWidth margin="normal" variant="outlined"
									error={errores.apellido}
									name="apellido"
									type="text"
									label="Apellido"
									value={cliente.apellido}
									onChange={handleChange}
									disabled={state.appReducer.actionsDisabled || isLoggedIn} /><br /><br />
							</div>
						</div>
						<div className="w3-row">
							<div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
								<TextField fullWidth margin="normal" variant="outlined"
									error={errores.razonSocial}
									name="razonSocial"
									type="text"
									label="Razón social"
									value={cliente.razonSocial}
									onChange={handleChange}
									disabled={state.appReducer.actionsDisabled} /><br /><br />
							</div>
							<div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
								<TextField fullWidth margin="normal" variant="outlined"
									error={errores.ruc}
									name="ruc"
									type="text"
									label="RUC (opcional)"
									value={cliente.ruc}
									onChange={handleChange}
									disabled={state.appReducer.actionsDisabled} /><br /><br />
							</div>
						</div>
						<div className="w3-row">
							<div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
								<TextField required fullWidth margin="normal" variant="outlined"
									error={errores.telefono}
									name="telefono"
									type="text"
									label="Teléfono"
									value={cliente.telefono}
									onChange={handleChange}
									disabled={state.appReducer.actionsDisabled || isLoggedIn} /><br /><br />
							</div>
							<div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
								<TextField required fullWidth margin="normal" variant="outlined"
									error={errores.email}
									name="email"
									type="email"
									label="Correo electrónico"
									value={cliente.email}
									onChange={handleChange}
									disabled={state.appReducer.actionsDisabled || isLoggedIn} /><br /><br />
							</div>
						</div>
						{((!isLoggedIn)) ? <div className="w3-row">
							<div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
								<FormControl variant="outlined" style={{ width: '100%' }}>
									<InputLabel htmlFor="input-password-registro">Crear contraseña para la cuenta *</InputLabel>
									<OutlinedInput
										error={errores.contrasena}
										id="input-password-registro"
										type={passwordVisible ? 'text' : 'password'}
										value={cliente.contrasena}
										onChange={handleChange}
										name="contrasena"
										disabled={(state.appReducer.actionsDisabled) ? true : false}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setPasswordVisible((visible) => !visible)}
													onMouseDown={e => e.preventDefault()}
													edge="end"
												>
													{passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
												</IconButton>
											</InputAdornment>
										}
										labelWidth={255}
									/>
								</FormControl><br /><br /><br />
							</div>
						</div> : null}

						{(!isLoggedIn) ? <div className="w3-row">
							<div className="w3-col l12 bs-bbox">
								<ReCAPTCHA
									sitekey={CONFIG.GOOGLE_RECAPTCHA_SITEKEY}
									onChange={onChangeRecaptcha}
									onExpired={onExpiredRecaptcha}
								/>
							</div>
						</div> : null}
					</div>
					<div className="w3-col l6 s12 w3-padding bs-bbox">
						<h3 className="w3-text-gray" style={{ margin: 0 }}>Tu pedido</h3>
						<div className="w3-col s12 w3-margin-top">
							<ProductosList
								productos={state.appReducer.itemsCarrito}
								totalLabel={'Subtotal'}
								actionsDisabled={state.appReducer.actionsDisabled}
								noActions
							/>
						</div>
						<table className="w3-table w3-border w3-bordered mi-tabla">
							<tbody className="w3-border">
								<tr>
									<td className="w3-padding" colSpan="3">
										<h3>Subtotal</h3>
									</td>
									<td className="w3-padding" colSpan="2">
										<h3 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(subtotal)}</h3>
									</td>
								</tr>
								{conPuntoEntrega ? <tr>
									<td className="w3-padding" colSpan="3">
										<h3>Envío a {state.appReducer.pedido.envio.descripcion}</h3>
									</td>
									<td className="w3-padding" colSpan="2">
										<h3 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(state.appReducer.pedido.envio.precio)}</h3>
									</td>
								</tr> : null}
								{(conPuntoEntrega && state.appReducer.pedido.datosUbicacion) ? <tr>
									<td className="w3-padding" colSpan="5">
										<div style={{ height: '200px', width: '100%' }}>
											<GoogleMapReact
												bootstrapURLKeys={{ key: CONFIG.GOOGLE_MAP_KEY }}
												defaultCenter={{
													lat: state.appReducer.pedido.datosUbicacion.lat,
													lng: state.appReducer.pedido.datosUbicacion.lng
												}}
												defaultZoom={15}
											>
												<Marker lat={state.appReducer.pedido.datosUbicacion.lat} lng={state.appReducer.pedido.datosUbicacion.lng} />
											</GoogleMapReact>
										</div>
										{state.appReducer.pedido.datosUbicacion ? <div className="w3-block datos-ubicacion">
											<TextField required fullWidth margin="normal" variant="outlined"
												name="direccion"
												type="text"
												label="Dirección"
												value={state.appReducer.pedido.datosUbicacion.direccion}
												disabled
											/>
											<br />
											<TextField required fullWidth margin="normal" variant="outlined"
												name="barrio"
												type="text"
												label="Barrio"
												value={state.appReducer.pedido.datosUbicacion.barrio}
												disabled
											/>
											<br />
											<TextField required fullWidth margin="normal" variant="outlined"
												name="ciudad"
												type="text"
												label="Ciudad"
												value={state.appReducer.pedido.datosUbicacion.ciudad}
												disabled
											/>
											<br />
										</div> : null}
									</td>
								</tr> : null}
								<tr>
									<td className="w3-padding" colSpan="3">
										<h3>Total</h3>
									</td>
									<td className="w3-padding" colSpan="2" style={{ minWidth: 180 }}>
										<h2 style={{ textAlign: 'right', color: Colors.THEME }}>{moneda} {Helper.numberUi(total)}</h2>
									</td>
								</tr>
								<tr>
									<td colSpan="5" style={{ padding: '5px' }}>
										<button className="w3-block w3-padding-16 w3-large w3-btn w3-text-white"
											style={{ backgroundColor: Colors.THEME }}
											onClick={() => realizarPedido()}>
												{isConBancard(state.appReducer.pedido?.tipo) ? 'PAGAR PEDIDO' : 'CONFIRMAR PEDIDO'}
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div> : <div className="w3-padding">
					<p className="w3-text-gray">Tu carrito está vacío</p>
					<Boton onClick={() => irA('/productos')}>Ir a la tienda</Boton>
				</div>}
			</div>
			{bancardProcessId !== null && (
				<BancardModal
					onHide={handleCloseBancardModal}
					onConfirm={handleBancardResponse}
					bancardProcessId={bancardProcessId}
					executeActionOnInit={stopLoading}
				/>
			)}
		</div>
	);
}
export default withRouter(FinalizarCompra);