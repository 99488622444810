import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import CONFIG from "config/environment";
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './mi-perfil.css';
import { Colors } from 'styles';
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import { useWindowSize } from 'hooks';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import { Boton, BotonSuccess } from 'components/material-ui';


Geocode.setApiKey(CONFIG.GOOGLE_MAP_KEY);
Geocode.setLanguage("en");


const Ubicaciones = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    // const [miUbicacionHabilitada, setMiUbicacionHabilitada] = useState(false);
    const [ubicacion, setUbicacion] = useState(null);
    const [lugares, setLugares] = useState(null);
    const [mapas, setMapas] = useState(null);
    const [mapa, setMapa] = useState(null);
    const [datosUbicacion, setDatosUbicacion] = useState(null);
    // const [errorDireccion, setErrorDireccion] = useState(false);
    // const [tabUbicacion, setTabUbicacion] = useState('mapa');
    // const [ubicacionMarcada, setUbicacionMarcada] = useState(null);
    const [errores, setErrores] = useState({});
    // const [edicion, setEdicion] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [original, setOriginal] = useState(null);
    const [abm, setAbm] = useState(null);
    const windowSize = useWindowSize();

    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Mi perfil | Ubicaciones';
        if (state.authReducer && state.authReducer.usuario && state.authReducer.hash) {
            getUbicaciones();
        } else {
            const { history } = props;
            if (history) history.push('/');
        }        
    }, []);

    const listaWidth = (wz) => {
        if (wz.width > 600) {
            return '240px';
        } else {
            return '100%';
        }
    }

    const getUbicaciones = () => {
        dispatch(AppActions.setActionsDisabled(true));
        dispatch(AppActions.setLoading(true));
        var options = {
            url: Api.route('u/mis-ubicaciones'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getUbicaciones response', response);
            dispatch(AppActions.setActionsDisabled(false));
            dispatch(AppActions.setLoading(false));
            if (response.data.error) {
                dispatch(AuthActions.setHash(null));
                enqueueSnackbar(response.data.error, { variant: 'error' });
            } else {
                dispatch(AuthActions.setUsuario({ ...state.authReducer.usuario, ubicaciones: response.data.datos }));
                if (response.data.datos && response.data.datos.length > 0) {
                    if (datosUbicacion && datosUbicacion.id) {
                        for (var i in response.data.datos) {
                            if (response.data.datos[i].id === datosUbicacion.id) {
                                verUbicacion(response.data.datos[i]);
                            }
                        }
                    } else {
                        verUbicacion(response.data.datos[0]);
                    }
                }
            }
        }, error => {
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            Helper.log('error getUbicaciones', error);
        });
    };


    const onChangeDatos = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setDatosUbicacion({ ...datosUbicacion, [name]: value });
        setErrores({ ...errores, [name]: false });
    };

    //Mapa
    const Marker = () => <FontAwesomeIcon icon={faMapMarkerAlt} color={Colors.THEME} size="3x" style={{
        position: "absolute",
        transform: "translate(-50%, -100%)"
    }} />;
    const center = {
        lat: -25.276750711185308,
        lng: -57.63522231951356
    };
    const zoom = 15;
    var optionsGetUbicacion = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };
    // function successGetUbicacion(pos) {
    //     setMiUbicacionHabilitada(true);
    // };
    // function errorGetUbicacion(err) {
    //     setMiUbicacionHabilitada(false);
    //     console.warn('ERROR(' + err.code + '): ' + err.message);
    // };
    function usarUbicacionSuccess(pos) {
        // setDatosUbicacion({ ...datosUbicacion, lat: pos.coords.latitude, lng: pos.coords.longitude });
        const bounds = new mapas.LatLngBounds();
        bounds.extend(new mapas.LatLng(pos.coords.latitude, pos.coords.longitude - 0.005562184202671051,));
        bounds.extend(new mapas.LatLng(pos.coords.latitude, pos.coords.longitude + 0.005562184202671051,));
        bounds.extend(new mapas.LatLng(pos.coords.latitude, pos.coords.longitude,));
        mapa.fitBounds(bounds);
        Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then(
            response => {
                //   const address = response.results[0].formatted_address;
                const geoData = Helper.getGeoData(response);
                setDatosUbicacion({
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                    nombre: datosUbicacion.nombre,
                    direccion: geoData.direccion ? geoData.direccion : '',
                    barrio: geoData.barrio ? geoData.barrio : '',
                    ciudad: geoData.ciudad ? geoData.ciudad : '',
                    departamento: geoData.departamento ? geoData.departamento : '',
                    pais: geoData.pais ? geoData.pais : '',
                    observaciones: datosUbicacion.observaciones
                });
                setErrores({});
                console.log(geoData);
            },
            error => {
                console.error(error);
            }
        );
    };
    function usarUbicacionError(err) {
        setUbicacion(null);
        enqueueSnackbar('No se pudo obtener tu ubicación', { variant: 'error' });
    };
    const usarMiUbicacion = () => {
        navigator.geolocation.getCurrentPosition(usarUbicacionSuccess, usarUbicacionError, optionsGetUbicacion);
    };
    const onClickMap = ({ x, y, lat, lng, event }) => {
        if (abm === 'edicion' || abm === 'nuevo') {
            setUbicacion({ lat: lat, lng: lng });
            Geocode.fromLatLng(lat, lng).then(response => {
                const geoData = Helper.getGeoData(response);
                let datos = {
                    id: null,
                    lat: lat,
                    lng: lng,
                    nombre: (datosUbicacion && datosUbicacion.nombre) ? datosUbicacion.nombre : '',
                    direccion: geoData.direccion ? geoData.direccion : '',
                    barrio: geoData.barrio ? geoData.barrio : '',
                    ciudad: geoData.ciudad ? geoData.ciudad : '',
                    departamento: geoData.departamento ? geoData.departamento : '',
                    pais: geoData.pais ? geoData.pais : '',
                    observaciones: (datosUbicacion && datosUbicacion.observaciones) ? datosUbicacion.observaciones : '',
                };
                if (abm === 'edicion') {
                    datos.id = datosUbicacion.id;
                    datos.nombre = datosUbicacion.nombre;
                    datos.observaciones = datosUbicacion.observaciones;
                }
                setDatosUbicacion({ ...datosUbicacion, ...datos});
                console.log(geoData);
            },
                error => {
                    console.error(error);
                });
        }
    };
    useEffect(() => {
        if (datosUbicacion && mapa && mapas) {
            const bounds = new mapas.LatLngBounds();
            bounds.extend(new mapas.LatLng(datosUbicacion.lat, datosUbicacion.lng - 0.005562184202671051,));
            bounds.extend(new mapas.LatLng(datosUbicacion.lat, datosUbicacion.lng + 0.005562184202671051,));
            bounds.extend(new mapas.LatLng(datosUbicacion.lat, datosUbicacion.lng,));
            mapa.fitBounds(bounds);
        }
    }, [mapas, mapa]);
    const handleApiLoaded = (map, maps, places) => {
        setMapas(maps);
        setMapa(map);
        setLugares(places);
    }
    const verUbicacion = (ubcn) => {
        setAbm('ver');
        setDatosUbicacion({
            id: ubcn.id,
            lat: parseFloat(ubcn.lat),
            lng: parseFloat(ubcn.lon),
            nombre: ubcn.nombre,
            direccion: ubcn.direccion,
            barrio: (ubcn.barrio && ubcn.barrio.nombre) ? ubcn.barrio.nombre : '',
            ciudad: (ubcn.ciudad && ubcn.ciudad.nombre) ? ubcn.ciudad.nombre : '',
            pais: (ubcn.pais && ubcn.pais.nombre) ? ubcn.pais.nombre : '',
            departamento: ubcn.departamento,
            observaciones: ubcn.observaciones
        });
        if (mapas) {
            const bounds = new mapas.LatLngBounds();
            bounds.extend(new mapas.LatLng(parseFloat(ubcn.lat), parseFloat(ubcn.lon) - 0.005562184202671051,));
            bounds.extend(new mapas.LatLng(parseFloat(ubcn.lat), parseFloat(ubcn.lon) + 0.005562184202671051,));
            bounds.extend(new mapas.LatLng(parseFloat(ubcn.lat), parseFloat(ubcn.lon),));
            mapa.fitBounds(bounds);
        }
    }
    const editar = () => {
        setOriginal({ ...datosUbicacion });
        setAbm('edicion');
    }
    const cancelar = () => {
        setDatosUbicacion({ ...original });
        setAbm('ver');
    }
    const eliminar = () => {
        setModalEliminar(true);
    }
    //ELIMINAR
    const confirmarEliminar = () => {
        var options = {
            url: Api.route('u/mis-ubicaciones/' + datosUbicacion.id),
            method: 'DELETE'
        };
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        Api.requestAxios(options, response => {
            Helper.log('deleteUbicacion response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                enqueueSnackbar(response.data.msg, { variant: 'success' });
                setModalEliminar(false);
                setDatosUbicacion(null);
                getUbicaciones();
            }
        }, error => {
            Helper.log('error deleteUbicacion', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al eliminar administrador', { variant: 'error' });
        });
    }
    const cancelarEliminar = () => {
        setModalEliminar(false);
    }

    const guardarEdicion = () => {
        let errors = {};
        let data = {};
        if (datosUbicacion.lat !== null && typeof datosUbicacion.lat !== 'undefined') {
            if (datosUbicacion.lat !== original.lat) {
                data.lat = datosUbicacion.lat;
            }
        } else {
            errors.lat = true;
        }
        if (datosUbicacion.lng !== null && typeof datosUbicacion.lng !== 'undefined') {
            if (datosUbicacion.lng !== original.lng) {
                data.lon = datosUbicacion.lng;
            }
        } else {
            errors.lng = true;
        }
        if (datosUbicacion.nombre) {
            if (datosUbicacion.nombre !== original.nombre) {
                data.nombre = datosUbicacion.nombre;
            }
        } else {
            errors.nombre = true;
        }
        if (datosUbicacion.direccion) {
            if (datosUbicacion.direccion !== original.direccion) {
                data.direccion = datosUbicacion.direccion;
            }
        } else {
            errors.direccion = true;
        }
        if (datosUbicacion.barrio) {
            if (datosUbicacion.barrio !== original.barrio) {
                data.barrio = datosUbicacion.barrio;
            }
        } else {
            errors.barrio = true;
        }
        if (datosUbicacion.ciudad) {
            if (datosUbicacion.ciudad !== original.ciudad) {
                data.ciudad = datosUbicacion.ciudad;
            }
        } else {
            errors.ciudad = true;
        }
        if (datosUbicacion.observaciones) {
            if (datosUbicacion.observaciones !== original.observaciones) {
                data.observaciones = datosUbicacion.observaciones;
            }
        } else {
            // errors.observaciones = true;
        }
        setErrores({ ...errors });
        if (Helper.isEmptyObject(errors) && !Helper.isEmptyObject(data)) {
            data.puntoId = datosUbicacion.id;
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var options = {
                url: Api.route('u/mis-ubicaciones/' + datosUbicacion.id),
                data: data,
                method: 'PUT'
            };
            Api.requestAxios(options, response => {
                Helper.log('edicionUbicacion response', response);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    if (response.data.msg) {
                        enqueueSnackbar(response.data.msg, { variant: 'success' });
                    }
                    getUbicaciones();
                }
            }, error => {
                Helper.log('error edicionUbicacion', error);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al guardar Ubicación', { variant: 'error' });
            });
        } else if (Helper.isEmptyObject(data)) {
            enqueueSnackbar('No hay cambios para guardar', { variant: 'info' });
        }
    }

    const nuevaUbicacion = () => {
        setAbm('nuevo');
        setDatosUbicacion({
            id: null,
            lat: null,
            lng: null,
            nombre: '',
            direccion: '',
            barrio: '',
            ciudad: '',
            pais: '',
            departamento: '',
            observaciones: ''
        });
    }
    const guardarNueva = () => {
        let errors = {};
        if (datosUbicacion.lat === null || typeof datosUbicacion.lat === 'undefined') {
            errors.lat = true;

        }
        if (datosUbicacion.lng === null || typeof datosUbicacion.lng === 'undefined') {
            errors.lng = true;

        }
        if (!datosUbicacion.nombre) {
            errors.nombre = true;
        }
        if (!datosUbicacion.direccion) {
            errors.direccion = true;
        }
        if (!datosUbicacion.barrio) {
            errors.barrio = true;
        }
        if (!datosUbicacion.ciudad) {
            errors.ciudad = true;
        }
        if (!datosUbicacion.observaciones) {
            // errors.observaciones = true;
        }
        setErrores({ ...errors });
        if (Helper.isEmptyObject(errors)) {
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var options = {
                url: Api.route('u/mis-ubicaciones'),
                data: {
                    lat: datosUbicacion.lat,
                    lon: datosUbicacion.lng,
                    nombre: datosUbicacion.nombre,
                    direccion: datosUbicacion.direccion,
                    barrio: datosUbicacion.barrio,
                    ciudad: datosUbicacion.ciudad,
                    observaciones: datosUbicacion.observaciones,
                },
                method: 'POST'
            };
            Api.requestAxios(options, response => {
                Helper.log('nuevaUbicacion response', response);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    if (response.data.msg) {
                        enqueueSnackbar(response.data.msg, { variant: 'success' });
                    }
                    getUbicaciones();
                }
            }, error => {
                Helper.log('error nuevaUbicacion', error);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al guardar Ubicación', { variant: 'error' });
            });
        }
    }
    return (
        <div id="pedidos" className="w3-block">
            <b>Ubicaciones</b>
            <div className="w3-row">
                <div className="w3-col" style={{ width: listaWidth(windowSize), borderRight: 'solid #E0E0E0 1px' }}>
                    {(state.authReducer.usuario && state.authReducer.usuario.ubicaciones && state.authReducer.usuario.ubicaciones.length > 0) ? <List>
                        {state.authReducer.usuario.ubicaciones.map((ubcn) => <ListItem key={ubcn.id} button onClick={() => verUbicacion(ubcn)} className={((datosUbicacion && ubcn.id === datosUbicacion.id) ? 'w3-light-gray' : '')}>
                            {/* <ListItemIcon><FontAwesomeIcon icon={faMapMarkerAlt} /></ListItemIcon>
                            <ListItemText primary={ubcn.nombre} /> */}
                            <div className="w3-row">
                                <div className="w3-col" style={{ width: '30px', padding: '10px 8px' }}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" color={((datosUbicacion && ubcn.id === datosUbicacion.id) ? Colors.THEME : Colors.GRAY_DARK)} />
                                </div>
                                <div className="w3-rest ellipsis">
                                    <span>{ubcn.nombre ? ubcn.nombre : 'Sin nombre'}</span><br />
                                    <span className="w3-text-gray w3-small">{ubcn.direccion ? ubcn.direccion : 'Dirección no especificada'}</span><br />
                                </div>
                            </div>
                        </ListItem>)}
                    </List> : null}
                    <div className="w3-block w3-center">
                        {abm !== 'nuevo' ? <BotonSuccess onClick={() => nuevaUbicacion()} variant="contained">Nueva ubicación</BotonSuccess> : null}
                        {(abm === 'nuevo' || abm === 'edicion') ? <Boton onClick={() => usarMiUbicacion()} color="default" variant="contained">Usar mi ubicación</Boton> : null}
                    </div>
                </div>
                <div className="w3-rest w3-mobile w3-padding-small bs-bbox">
                    <div className="w3-block">
                        <div style={{ height: '250px', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: CONFIG.GOOGLE_MAP_KEY }}
                                onClick={onClickMap}
                                defaultCenter={center}
                                defaultZoom={zoom}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({ map, maps, places }) => handleApiLoaded(map, maps, places)}
                            >
                                {(datosUbicacion) ? <Marker lat={datosUbicacion.lat} lng={datosUbicacion.lng} /> : null}
                            </GoogleMapReact>
                        </div>
                        {(abm === 'edicion') ? <div className="w3-block">
                            <div className="w3-row">
                                <div className="w3-col l12">
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        error={errores.nombre}
                                        name="nombre"
                                        type="text"
                                        label="Nombre"
                                        value={datosUbicacion.nombre}
                                        onChange={onChangeDatos}
                                        disabled={state.appReducer.actionsDisabled} /><br />
                                </div>
                                <div className="w3-col l12">
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        error={errores.direccion}
                                        name="direccion"
                                        type="text"
                                        label="Dirección"
                                        value={datosUbicacion.direccion}
                                        onChange={onChangeDatos}
                                        disabled={state.appReducer.actionsDisabled} /><br />
                                </div>

                            </div>
                            <div className="w3-row">
                                <div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        error={errores.barrio}
                                        name="barrio"
                                        type="text"
                                        label="Barrio"
                                        value={datosUbicacion.barrio}
                                        onChange={onChangeDatos}
                                        disabled={state.appReducer.actionsDisabled} /><br />
                                </div>
                                <div className="w3-col l6" >
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        error={errores.ciudad}
                                        name="ciudad"
                                        type="text"
                                        label="Ciudad"
                                        value={datosUbicacion.ciudad}
                                        onChange={onChangeDatos}
                                        disabled={state.appReducer.actionsDisabled} /><br />

                                </div>

                            </div>
                            <div className="w3-row">
                                <div className="w3-col l12">
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        error={errores.observaciones}
                                        name="observaciones"
                                        type="text"
                                        label="Observaciones"
                                        value={datosUbicacion.observaciones}
                                        onChange={onChangeDatos}
                                        disabled={state.appReducer.actionsDisabled} /><br />
                                </div>
                            </div>
                            <div className="w3-block w3-center">
                                <BotonSuccess onClick={() => guardarEdicion()} variant="contained">Guardar</BotonSuccess>
                                <Boton onClick={() => cancelar()} color="default" variant="contained">Cancelar</Boton>
                            </div>
                        </div> : null}
                        {(abm === 'ver' && datosUbicacion) ? <div className="w3-block">
                            <div className="w3-row">
                                <div className="w3-col l12">
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        name="nombre"
                                        type="text"
                                        label="Nombre"
                                        value={datosUbicacion.nombre} /><br />
                                </div>
                                <div className="w3-col l12">
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        name="direccion"
                                        type="text"
                                        label="Dirección"
                                        value={datosUbicacion.direccion} /><br />
                                </div>

                            </div>
                            <div className="w3-row">
                                <div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        name="barrio"
                                        type="text"
                                        label="Barrio"
                                        value={datosUbicacion.barrio} /><br />
                                </div>
                                <div className="w3-col l6" >
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        name="ciudad"
                                        type="text"
                                        label="Ciudad"
                                        value={datosUbicacion.ciudad} /><br />

                                </div>

                            </div>
                            <div className="w3-row">
                                <div className="w3-col l12">
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        name="observaciones"
                                        type="text"
                                        label="Observaciones"
                                        value={datosUbicacion.observaciones} /><br />
                                </div>
                            </div>
                            <div className="w3-block w3-center">
                                <Boton onClick={() => editar()} color="primary" variant="contained">Editar</Boton>
                                <Boton onClick={() => eliminar()} color="secondary" variant="contained">Eliminar</Boton>
                            </div>
                        </div> : null}
                        {(abm === 'nuevo') ? <div className="w3-block">
                            <div className="w3-row">
                                <div className="w3-col l12">
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        error={errores.nombre}
                                        name="nombre"
                                        type="text"
                                        label="Nombre"
                                        value={datosUbicacion.nombre}
                                        onChange={onChangeDatos}
                                        disabled={state.appReducer.actionsDisabled} /><br />
                                </div>
                                <div className="w3-col l12">
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        error={errores.direccion}
                                        name="direccion"
                                        type="text"
                                        label="Dirección"
                                        value={datosUbicacion.direccion}
                                        onChange={onChangeDatos}
                                        disabled={state.appReducer.actionsDisabled} /><br />
                                </div>

                            </div>
                            <div className="w3-row">
                                <div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        error={errores.barrio}
                                        name="barrio"
                                        type="text"
                                        label="Barrio"
                                        value={datosUbicacion.barrio}
                                        onChange={onChangeDatos}
                                        disabled={state.appReducer.actionsDisabled} /><br />
                                </div>
                                <div className="w3-col l6" >
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        error={errores.ciudad}
                                        name="ciudad"
                                        type="text"
                                        label="Ciudad"
                                        value={datosUbicacion.ciudad}
                                        onChange={onChangeDatos}
                                        disabled={state.appReducer.actionsDisabled} /><br />

                                </div>

                            </div>
                            <div className="w3-row">
                                <div className="w3-col l12">
                                    <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                        error={errores.observaciones}
                                        name="observaciones"
                                        type="text"
                                        label="Observaciones"
                                        value={datosUbicacion.observaciones}
                                        onChange={onChangeDatos}
                                        disabled={state.appReducer.actionsDisabled} /><br />
                                </div>
                            </div>
                            <div className="w3-block w3-center">
                                <BotonSuccess onClick={() => guardarNueva()} color="primary" variant="contained">Guardar</BotonSuccess>
                            </div>
                        </div> : null}
                    </div>
                </div>
            </div>
            {(modalEliminar && datosUbicacion) ? <div className="w3-modal w3-show">
                <div className="w3-modal-content w3-animate-zoom w3-round-large">
                    <div className="w3-row">
                        <div className="w3-col w3-right" style={{ width: '50px' }}>
                            <IconButton aria-label="delete" onClick={() => cancelarEliminar()}>
                                &nbsp;<FontAwesomeIcon icon={faTimes} />&nbsp;
                            </IconButton>
                        </div>
                        <div className="w3-rest w3-padding w3-center w3-text-gray">
                            <h2 style={{ margin: 0 }}><FontAwesomeIcon icon={faExclamationTriangle} /> Atención</h2>
                        </div>
                    </div>
                    <div className="w3-container w3-padding">
                        <p className="w3-large w3-margin w3-center">Estás a punto de eliminar la ubicación: {datosUbicacion.nombre}. Ésta acción no se puede deshacer.</p>
                        <p className="w3-large w3-margin w3-center">¿Quieres continuar?</p>
                        <div className="w3-row w3-white">
                            <div className="w3-col l6 bs-bbox" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                    type="button"
                                    onClick={() => cancelarEliminar()}>No</button>
                            </div>
                            <div className="w3-col l6 bs-bbox" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                                    type="button"
                                    onClick={() => confirmarEliminar()}>Si</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}

        </div>
    );
}
export default withRouter(Ubicaciones);