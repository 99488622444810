import React, { useContext, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import { useWindowSize } from 'hooks';
import { Logo } from 'assets/logos';
import { faShoppingCart, faBars, faHome, faTags, faUserAlt, faUsers, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from "@material-ui/core/styles";
import SlidingPanel from 'react-sliding-side-panel';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge'
import './header.css';

const useStyles = makeStyles({
	badge: {
		height: 16,
		minWidth: 16,
		fontSize: 8,
		padding: '2px 1px'
	},
});

const Header = (props) => {
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	const iconColor = '#C5C7C8';
	const [open, setOpen] = React.useState(false);
	const windowSize = useWindowSize();
	const classes = useStyles()

	const numItemsEnCarrito = Object.keys(state.appReducer?.itemsCarrito || {}).length

	useEffect(() => {
		// console.log('props.history', props.history);      
	}, []);

	const slideSize = (wz) => {
		return Math.floor((240 * 100) / wz.width);
	}

	const miPerfil = (event) => {
		event.preventDefault();
		if (state.authReducer.usuario && state.authReducer.usuario.id) {
			dispatch(AppActions.setLastVisited('mi-perfil'));
			const { history } = props;
			if (history) history.push('/mi-perfil/mis-datos');
		} else {
			dispatch(AuthActions.setLoginForm(true));
		}
	};

	const abrir = (path) => {
		setOpen(false);
		dispatch(AppActions.setLastVisited(path));
		const { history } = props;
		if (history) history.push(path);
	};

	const hayUsuario = state.authReducer?.usuario?.id

	return (
		<>
			<div className="w3-block w3-hide-small">
				<div className="w3-block">
					<div className="w3-content">
						<div className="w3-row">
							<div className="w3-col logo-container">
								<Link to="/"><img src={Logo} alt="Logo" /></Link>
							</div>
							{!hayUsuario ? <div className="w3-col w3-right header-link-container" style={{ 'width': '100px' }}>
								<Link className="header-link" to="/registro"><span style={{ color: iconColor }}>|</span> Registrate</Link>
							</div> : null}
							<div className="w3-col w3-right header-link-container" style={{ 'width': '120px' }}>
								<a className={"header-link" + ((props.history.location.pathname.includes('/mi-perfil')) ? ' active' : '')} href="/" onClick={miPerfil}>
									<FontAwesomeIcon icon={faUser} color={iconColor} />
									{hayUsuario ? ' Mi Perfil' : ' Iniciar sesión'}
								</a>
							</div>
							<div className="w3-col w3-right header-link-container" style={{ 'width': '135px' }}>
								<Link
									className={'header-link' + ((props.history.location.pathname === '/carrito') ? ' active' : '')}
									to="/carrito"><FontAwesomeIcon
										icon={faShoppingCart}
										color={iconColor}
									/>
									{` Mi carrito${numItemsEnCarrito > 0 ? (' (' + numItemsEnCarrito + ')') : ''}`}
								</Link>
							</div>
							<div className="w3-rest"></div>
						</div>
					</div>
				</div>
				<div className="w3-block" style={{ 'backgroundColor': '#F4F4F6' }}>
					<div className="w3-content">
						<nav className="w3-row">
							<div className="w3-col l3 m3 nav-link-container">
								<Link to="/" className={'nav-link' + ((props.history.location.pathname === '/') ? ' active' : '')}>Inicio</Link>
							</div>
							<div className="w3-col l3 m3 nav-link-container">
								<Link to="/acerca-de" className={'nav-link' + ((props.history.location.pathname === '/acerca-de') ? ' active' : '')}>Acerca de</Link>
							</div>
							<div className="w3-col l3 m3 nav-link-container">
								<Link to="/productos" className={'nav-link' + ((props.history.location.pathname === '/productos') ? ' active' : '')}>Productos</Link>
							</div>
							<div className="w3-col l3 m3 nav-link-container">
								<Link to="/contacto" className={'nav-link' + ((props.history.location.pathname === '/contacto') ? ' active' : '')}>Contacto</Link>
							</div>
						</nav>
					</div>
				</div>
			</div>
			<div className="w3-block w3-hide-medium w3-hide-large">
				<div className="w3-row">
					<div className="w3-col" style={{ width: '30px' }}>
						<button id="boton-bars" className="w3-btn w3-xxlarge" onClick={() => setOpen(true)}>
							<FontAwesomeIcon icon={faBars} />
						</button>
					</div>
					<div className="w3-col w3-right header-link-mobile-container" style={{ 'width': '40px' }}>
						<a
							className={"header-mobile-link" + ((props.history.location.pathname.includes('/mi-perfil')) ? ' active' : '')}
							href="/"
							onClick={miPerfil}>
							<FontAwesomeIcon icon={faUser} color={iconColor} size='lg'
							/>
						</a>
					</div>
					<div className="w3-col w3-right header-link-mobile-container" style={{ 'width': '40px' }}>
						<Link className="header-mobile-link" to="/carrito">
							<Badge badgeContent={numItemsEnCarrito} color="secondary" classes={{ badge: classes.badge }}>
								<FontAwesomeIcon icon={faShoppingCart} color={iconColor} size='lg' />
							</Badge>
						</Link>
					</div>
					<div className="w3-rest w3-padding w3-center logo-mobile-container">
						<Link to="/"><img src={Logo} alt="Logo" /></Link>
					</div>
				</div>
			</div>
			<SlidingPanel type={'left'} isOpen={open} panelClassName="w3-white" size={slideSize(windowSize)} backdropClicked={() => setOpen(false)}>
				<div className="w3-padding-small bs-bbox" style={{ width: '235px' }}>
					<div className="w3-block w3-center logo-container">
						<Link to="/"><img src={Logo} alt="Logo" /></Link>
					</div>
					<div className="bs-bbox">
						<List>
							<ListItem button onClick={() => abrir('/')} className={'drawer-link' + ((props.history.location.pathname === '/') ? ' active' : '')}>
								<ListItemIcon><FontAwesomeIcon icon={faHome} /></ListItemIcon>
								<ListItemText primary="Inicio" />
							</ListItem>
							<ListItem button onClick={() => abrir('/acerca-de')} className={'drawer-link' + ((props.history.location.pathname === '/acerca-de') ? ' active' : '')}>
								<ListItemIcon><FontAwesomeIcon icon={faUsers} /></ListItemIcon>
								<ListItemText primary="Acerca de" />
							</ListItem>
							<ListItem button onClick={() => abrir('/productos')} className={'drawer-link' + ((props.history.location.pathname === '/productos') ? ' active' : '')}>
								<ListItemIcon><FontAwesomeIcon icon={faTags} /></ListItemIcon>
								<ListItemText primary="Productos" />
							</ListItem>
							<ListItem button onClick={() => abrir('/contacto')} className={'drawer-link' + ((props.history.location.pathname === '/contacto') ? ' active' : '')}>
								<ListItemIcon><FontAwesomeIcon icon={faEnvelope} /></ListItemIcon>
								<ListItemText primary="Contacto" />
							</ListItem>
						</List>
						<Divider />
						<List>
							<ListItem button onClick={miPerfil} className={'drawer-link' + ((props.history.location.pathname.includes('/mi-perfil')) ? ' active' : '')}>
								<ListItemIcon><FontAwesomeIcon icon={faUserAlt} /></ListItemIcon>
								<ListItemText primary="Mi perfil" />
							</ListItem>
						</List>
					</div>
				</div>
			</SlidingPanel>
		</>
	);
}
export default withRouter(Header);