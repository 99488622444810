import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Helper } from 'utils';
import CONFIG from "config/environment";
import { Api } from 'services';
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './carrito.css';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Colors } from 'styles';
import { useSnackbar } from 'notistack';
import { GoogleMap, SelectorUbicaciones } from 'components/GoogleMap';
import { ENTREGA_TIPO_ENVIO, ENTREGA_TIPO_ENVIO_INTERIOR, ENTREGA_TIPO_ONLINE_ENVIO, OPCIONES_TIPOS_PEDIDO } from 'config/pedidos';
import { isConSeleccionPuntoEntrega } from 'config/pedidos';
import ProductosList from 'components/producto/ProductosList';


const Boton = withStyles({
	root: {
		background: `${Colors.THEME}`,
		borderRadius: 3,
		border: 0,
		color: 'white',
		'&:hover': {
			background: `${Colors.THEME_D4}`,
		}
	},
	label: {
		textTransform: 'uppercase',
	}
})(Button);

const RadioTheme = withStyles({
	root: {
		color: Colors.THEME,
		'&$checked': {
			color: Colors.THEME_D1,
		},
		padding: '5px 9px'
	}
})((props) => <Radio color="default" {...props} />);

const Carrito = props => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);

	const opcionesTiposPedido = OPCIONES_TIPOS_PEDIDO

	const [subTotal, setSubTotal] = useState(0);
	const [total, setTotal] = useState(0);
	const [tipo, setTipo] = useState(opcionesTiposPedido[0].value);
	const [moneda, setMoneda] = useState('₲');
	const [datosUbicacion, setDatosUbicacion] = useState(null);
	const [tabUbicacion, setTabUbicacion] = useState('mapa');
	const [ubicaciones, setUbicaciones] = useState([]);
	const [precioEnvio, setPrecioEnvio] = useState(0)

	const poligono = state.appReducer.areaCobertura[datosUbicacion?.indicePoligono]
	const envio = poligono ? { precio: poligono.precio, descripcion: poligono.nombre } : null

	useEffect(() => {
		getConfig();
	}, []);

	const getConfig = () => {
		var options = {
			url: Api.route('p/configuraciones'),
			method: 'GET'
		};
		Api.requestAxios(options, response => {
			Helper.log('getConfiguraciones response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				//setConfiguraciones(response.data.datos);
				dispatch(AppActions.setAreaCobertura(response.data.datos.areaCobertura));
				if (state.authReducer && state.authReducer.usuario && state.authReducer.usuario.id) {
					getUbicaciones();
				}
			}
		}, error => {
			Helper.log('error getConfiguraciones', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			enqueueSnackbar('Error al traer datos de configuración', { variant: 'error' });
		});
	}

	const getUbicaciones = () => {
		dispatch(AppActions.setActionsDisabled(true));
		dispatch(AppActions.setLoading(true));
		var options = {
			url: Api.route('u/mis-ubicaciones'),
			method: 'GET'
		};
		Api.requestAxios(options, response => {
			Helper.log('getUbicaciones response', response);
			dispatch(AppActions.setActionsDisabled(false));
			dispatch(AppActions.setLoading(false));
			if (response.data.error) {
				dispatch(AuthActions.setHash(null));
				enqueueSnackbar(response.data.error, { variant: 'error' });
			} else {
				let ubs = [];
				for (var i in response.data.datos) {
					ubs.push(response.data.datos[i]);
				}
				dispatch(AuthActions.setUsuario({ ...state.authReducer.usuario, ubicaciones: ubs }));
				setUbicaciones(ubs);
			}
		}, error => {
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			Helper.log('error getUbicaciones', error);
		});
	};

	useEffect(() => {
		dispatch(AppActions.setLoading(false));
		dispatch(AppActions.setActionsDisabled(false));
		document.title = CONFIG.APP_NAME + ' - Carrito';
	}, []);

	useEffect(() => {
		let sTotal = 0;
		for (var i in state.appReducer.itemsCarrito) {
			sTotal = sTotal + (state.appReducer.itemsCarrito[i].precio * state.appReducer.itemsCarrito[i].cantidad);
		}

		setSubTotal(sTotal);
		const total = sTotal + precioEnvio
		setTotal(total)
	}, [state.appReducer.itemsCarrito, precioEnvio]);

	useEffect(() => {
		let precio = 0

		if (isConSeleccionPuntoEntrega(tipo)) {
			precio = (poligono?.precio || 0);
		}

		setPrecioEnvio(precio)
	}, [datosUbicacion?.indicePoligono])

	const menos = (item) => {
		if (item.cantidad === 1) {
			let items = { ...state.appReducer.itemsCarrito };
			delete items[item.id];
			dispatch(AppActions.setItemsCarrito({ ...items }));
		} else {
			item.cantidad = item.cantidad - 1;
			dispatch(AppActions.setItemsCarrito({ ...state.appReducer.itemsCarrito, [item.id]: item }));
		}
	}
	const mas = (item) => {
		item.cantidad = item.cantidad + 1;
		dispatch(AppActions.setItemsCarrito({ ...state.appReducer.itemsCarrito, [item.id]: item }));
	}
	const onChangeCantidad = (e, item) => {
		e.preventDefault();
		if (e && e.target && e.target.value) {
			let num = Number(e.target.value);
			if (Number.isInteger(num)) {
				item.cantidad = num;
				dispatch(AppActions.setItemsCarrito({ ...state.appReducer.itemsCarrito, [item.id]: item }));
			}
		}
	}
	const quitarDelCarrito = (id) => {
		if (state.appReducer.itemsCarrito[id]) {
			let items = { ...state.appReducer.itemsCarrito };
			delete items[id];
			dispatch(AppActions.setItemsCarrito({ ...items }));
			enqueueSnackbar('Producto eliminado del carrito', { variant: 'info' });
		}
	}
	const onChangeTipo = (event) => {
		setTipo(event.target.value);
		if (isConSeleccionPuntoEntrega(event.target.value)) {
			setTabUbicacion('mapa');
		} else {
			setDatosUbicacion(null)
		}
	};
	const irA = (path) => {
		const { history } = props;
		if (history) history.push(path);
	};
	const getItemsDelCarrito = () => {
		let items = []
		for (var i in state.appReducer.itemsCarrito) {
			items.push({
				itemId: state.appReducer.itemsCarrito[i].id,
				cantidad: state.appReducer.itemsCarrito[i].cantidad,
			})
		}
		return items;
	}
	const procesar = () => {
		if (isConSeleccionPuntoEntrega(tipo) && datosUbicacion && !datosUbicacion.id) {
			dispatch(AppActions.setLoading(true));
			dispatch(AppActions.setActionsDisabled(true));
			var options = {
				url: Api.route('p/nueva-ubicacion'),
				data: {
					lat: datosUbicacion.lat,
					lon: datosUbicacion.lng,
					direccion: datosUbicacion.direccion,
					barrio: datosUbicacion.barrio,
					ciudad: datosUbicacion.ciudad,
					pais: datosUbicacion.pais
				},
				method: 'POST'
			};
			Api.requestAxios(options, response => {
				Helper.log('nuevaUbicacion response', response);
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
				if (response.data.error) {
					enqueueSnackbar(response.data.msg, { variant: 'error' });
				} else {
					if (state.appReducer && state.appReducer.pedido) {
						dispatch(AppActions.setPedido({
							...state.appReducer.pedido,
							puntoEntregaId: response.data.datos,
							items: getItemsDelCarrito(),
							datosUbicacion: datosUbicacion,
							tipo,
							envio
						}));
					} else {
						dispatch(AppActions.setPedido({
							puntoEntregaId: response.data.datos,
							items: getItemsDelCarrito(),
							datosUbicacion: datosUbicacion,
							tipo,
							envio
						}));
					}
					const { history } = props;
					if (history) history.push('finalizar-compra');
				}
			}, error => {
				Helper.log('error nuevaUbicacion', error);
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
				enqueueSnackbar('Error al subir nuevo producto', { variant: 'error' });
			});
		} else if (isConSeleccionPuntoEntrega(tipo) && datosUbicacion && datosUbicacion.id) {
			dispatch(AppActions.setPedido({
				puntoEntregaId: datosUbicacion.id,
				items: getItemsDelCarrito(),
				datosUbicacion: datosUbicacion,
				tipo,
				envio
			}));
			const { history } = props;
			if (history) history.push('finalizar-compra');
		} else {
			dispatch(AppActions.setPedido({ items: getItemsDelCarrito(), tipo, envio }));
			const { history } = props;
			if (history) history.push('finalizar-compra');
		}
	};

	const elegirUbicacion = (ubicacion) => {
		setDatosUbicacion({
			id: ubicacion.id,
			lat: parseFloat(ubicacion.lat),
			lng: parseFloat(ubicacion.lon),
			direccion: ubicacion.direccion,
			barrio: ubicacion.barrio.nombre,
			ciudad: ubicacion.ciudad.nombre,
		});
	}


	return (
		<div id="pagina-carrito" className="w3-block content-min-height">
			<div className="w3-content">
				<div className="w3-block w3-padding">
					<h2 className="w3-text-gray">Carrito</h2>
				</div>
				{!Helper.isEmptyObject(state.appReducer.itemsCarrito) ? <div className="w3-row">
					<div className="w3-col l8 s12 w3-padding bs-bbox">
						<ProductosList
							productos={state.appReducer.itemsCarrito}
							quitarDelCarrito={quitarDelCarrito}
							menos={menos}
							mas={mas}
							onChangeCantidad={onChangeCantidad}
							actionsDisabled={state.appReducer.actionsDisabled}
						/>
					</div>
					<div className="w3-col l4 s12 w3-padding bs-bbox">
						<div className="w3-border">
							<div className="w3-padding w3-center w3-border-bottom">
								<h2>Total carrito</h2>
							</div>
							<div className="w3-padding" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<h3>Subtotal</h3>
								<h3>{moneda} {Helper.numberUi(subTotal)}</h3>
							</div>
							<div className="w3-padding w3-border-bottom">
								<h4 style={{ marginBottom: 5 }}>Tipo de entrega</h4>
								<div>
									<FormControl component="fieldset" style={{ width: '100%' }}>
										<RadioGroup aria-label="tipo" name="tipo" value={tipo} onChange={(e) => onChangeTipo(e)}>
											{opcionesTiposPedido.map((opcion, i) => {
												let costoEnvioText = `${moneda} 0`
												switch (opcion.value) {
													case ENTREGA_TIPO_ONLINE_ENVIO:
													case ENTREGA_TIPO_ENVIO:
														costoEnvioText = moneda + ' ' + Helper.numberUi(precioEnvio)
														break
													case ENTREGA_TIPO_ENVIO_INTERIOR:
														costoEnvioText = 'A definir'
												}

												return (
													<>
														<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
															<FormControlLabel key={`tipo-${i}`} value={opcion.value} control={<RadioTheme />} label={opcion.label} />
															<h4 key={`tipo-precio-${i}`} style={{ textAlign: 'right', marginBottom: 0, marginTop: 5 }}>{costoEnvioText}</h4>
														</div>
														{opcion.text ? (
															<small style={{ fontWeight: 'bold', marginLeft: 30, maxWidth: 'calc(100% - 100px)', color: '#9aa0a5' }}>{opcion.text}</small>
														) : null}
													</>
												)
											})}
										</RadioGroup>
									</FormControl>
								</div>
							</div>
							{isConSeleccionPuntoEntrega(tipo) ? (
								<div className="w3-padding w3-border-bottom">
									<div>
										{(state.authReducer && state.authReducer.usuario && state.authReducer.usuario.ubicaciones && state.authReducer.usuario.ubicaciones.length > 0) ? <FormControl component="fieldset">
											<RadioGroup aria-label="ubicacion" name="tab-ubicacion" value={tabUbicacion} onChange={(e) => { setTabUbicacion(e.target.value); }}>
												<FormControlLabel value="lista" control={<RadioTheme />} label="Ubicaciones guardadas" />
												<FormControlLabel value="mapa" control={<RadioTheme />} label="Indicar en el mapa" />
											</RadioGroup>
										</FormControl> : null}
										<div style={{ marginTop: 15 }}>
											<GoogleMap
												mapHeight={250}
												mapWidth='100%'
												areas={state.appReducer.areaCobertura}
												ubicacion={datosUbicacion}
												setUbicacion={setDatosUbicacion}
												disabled={state.appReducer.actionsDisabled || tabUbicacion === 'lista'}
											/>
										</div>
										{tabUbicacion === 'lista' ? <div>
											<SelectorUbicaciones
												ubicaciones={ubicaciones}
												elegirUbicacion={elegirUbicacion}
												datosUbicacion={datosUbicacion}
											/>
										</div> : null}
									</div>
								</div>
							) : null}
							<div className="w3-padding w3-border-bottom" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
								<h3>Total</h3>
								<h2 style={{ textAlign: 'right', color: Colors.THEME }}>{moneda} {Helper.numberUi(total)}</h2>
							</div>
							<div style={{ padding: 5 }}>
								<button className="w3-block w3-padding-16 w3-large w3-btn w3-text-white"
									style={{ backgroundColor: Colors.THEME }}
									onClick={() => procesar()}
									disabled={isConSeleccionPuntoEntrega(tipo) && envio === null}
								>
									CONTINUAR
								</button>
								<Alert severity="info" sx={{ mt: 2 }}>
									<i>Podrás volver a revisar tu pedido antes de confirmar.</i>
								</Alert>
							</div>
						</div>
					</div>
				</div> : <div className="w3-padding">
					<p className="w3-text-gray">Tu carrito está vacío</p>
					<Boton onClick={() => irA('/productos')}>Ir a la tienda</Boton>
				</div>}
			</div>
		</div >
	);
}
export default withRouter(Carrito);