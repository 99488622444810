export const ENTREGA_TIPO_RETIRO_TIENDA = 'tienda' // confirmar pedido sin pagar
export const ENTREGA_TIPO_ENVIO = 'envio' // confirmar pedido sin pagar, pero eligiendo ubicación
export const ENTREGA_TIPO_ENVIO_INTERIOR = 'envio-interior' // confirmar pedido sin pagar
export const ENTREGA_TIPO_ONLINE_RETIRO_TIENDA = 'online-tienda' // confirmar pedido al pagar
export const ENTREGA_TIPO_ONLINE_ENVIO = 'online-envio' // confirmar pedido al pagar, eligiendo ubicación

// Modificar esto de acuerdo a las necesidades del proyecto
export const OPCIONES_TIPOS_PEDIDO = [
	{
		label: 'Pago online con envío',
		text: '',
		value: ENTREGA_TIPO_ONLINE_ENVIO
	},
	{
		label: 'Envío con pago contra entrega',
		text: 'Podrás pagar con POS o transferencia bancaria',
		value: ENTREGA_TIPO_ENVIO
	},
	{
		label: 'Envío al interior',
		text: 'Costos por cuenta del cliente. Te contactaremos para coordinar el envío',
		value: ENTREGA_TIPO_ENVIO_INTERIOR
	},
]

export const tipoTextoMap = {
	[ENTREGA_TIPO_ONLINE_RETIRO_TIENDA]: 'Retiro del local',
	[ENTREGA_TIPO_ENVIO]: 'Pago contra entrega',
	[ENTREGA_TIPO_ENVIO_INTERIOR]: 'Envío al interior',
	[ENTREGA_TIPO_ONLINE_ENVIO]: 'Pagado con envío'
}

export const isConBancard = (tipo) => {
	let conBancard = false
	if (tipo === 'online-tienda' || tipo === 'online-envio') {
		conBancard = true
	}

	return conBancard
}

export const isConSeleccionPuntoEntrega = (tipo) => {
	let conSeleccion = false
	if (tipo === 'envio' || tipo === 'online-envio') {
		conSeleccion = true
	}

	return conSeleccion
}

// Los elementos del array se utilizan tanto como value como para label
export const OPCIONES_CANTIDAD_PEDIDOS = [
	10, 20, 30, 50, 100
]