import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from "react-router-dom";
import { Helper } from 'utils';
import { Api } from 'services';
import CONFIG from "config/environment";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';

import './acerca-de.css';
import { useSnackbar } from 'notistack';

const AcercaDe = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    // const state = useContext(StateContext);
    const [contenido, setContenido] = useState(null);
    const [imagenesListas, setImagenesListas] = useState(false);
    // const [idioma, setIdioma] = useState('es');
    const idioma = 'es';
    const [texto, setTexto] = useState(null);
    const [imagenPortada, setImagenPortada] = useState(null);
    useEffect(() => {
        document.title = CONFIG.APP_NAME + ' - Acerca de';
        getContenidoSeccion();
    }, []);
    useEffect(() => {
        if (contenido) {
            if (contenido['acerca-de'] && contenido['acerca-de'].length > 0) {
                let imagenes = [];
                if (contenido['acerca-de'][0] && contenido['acerca-de'][0].partes && contenido['acerca-de'][0].partes.length > 0) {

                    for (var i in contenido['acerca-de'][0].partes) {
                        if (contenido['acerca-de'][0].partes[i].grupos) {
                            let grupos = contenido['acerca-de'][0].partes[i].grupos;                            
                            for (var keyGrupo in grupos) {
                                let grupo = grupos[keyGrupo];
                                for (var keyValor in grupo) {
                                    let valor = grupo[keyValor];
                                    if (keyValor === 'texto' && valor[0] && valor[0].idioma === idioma) {
                                        setTexto(valor[0].valor);
                                    }
                                    if (keyValor === 'imagen' && valor[0]) {
                                        setImagenPortada(CONFIG.URL_BASE + valor[0].valor);
                                        imagenes.push(CONFIG.URL_BASE + valor[0].valor);
                                    }
                                }
                            }
                        }
                    }
                }
                cargaImagenes(imagenes);
            }
        }
    }, [contenido]);
    const getContenidoSeccion = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('p/seccion/acerca-de'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getContenidoSeccion response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                if (response.data.datos) {
                    setContenido(response.data.datos);
                }
            }
        }, error => {
            Helper.log('error getContenidoSeccion', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos', { variant: 'error' });
        });
    }
    const cargaImagenes = (imagenes) => {
        var cantImgs = imagenes.length;
        var cargadas = 0;
        function loadImgs() {
            cargadas = cargadas + 1;
            if (cargadas === cantImgs) {
                setImagenesListas(true);
            }
        };
        if (cantImgs > 0) {
            for (var i in imagenes) {
                var image = new Image();
                image.src = imagenes[i];
                image.onload = function () {
                    loadImgs();
                };
            }
        } else {
            setImagenesListas(true);
        }
    }
    return (
        <div id="pagina-acerca-de" className="w3-block content-min-height">
            <div className="w3-content">
                <div className="w3-block w3-padding">
                    <h2 className="w3-text-gray">Acerca de</h2>
                </div>
                <div className="w3-block" id="img-nosotros-container">
                    <div className="w3-padding bs-bbox">
                        <div>
                            {imagenPortada && imagenesListas ? <img src={imagenPortada} alt="" style={{ width: '100%' }} /> : null}
                        </div>
                    </div>
                </div>
                <div className="w3-block w3-padding w3-text-gray">
                    {texto ? <div dangerouslySetInnerHTML={{ __html: texto }} /> : null}
                </div>
            </div>
        </div>
    );
}
export default withRouter(AcercaDe);