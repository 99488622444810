import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config/environment";
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './mi-perfil.css';
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router-dom";


import { KeyboardDatePicker } from '@material-ui/pickers';

import moment from 'moment';
import ListaPedidos from 'components/pedidos/ListaPedidos';
import { OPCIONES_CANTIDAD_PEDIDOS } from 'config/pedidos';


const Pedidos = props => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	const [pedidos, setPedidos] = useState([]);
	// const [pedido, setPedido] = useState(null);
	// const [panelVer, setPanelVer] = useState(false);
	//SEARCH
	// let searchInput = React.createRef();
	const [requestEnProceso, setRequestEnProceso] = useState(false);
	// const [searchText, setSearchText] = useState('');
	// const [typing, setTyping] = useState(false);
	// const [typingTimeout, setTypingTimeout] = useState(0);
	const [fechaDesde, setFechaDesde] = useState(null);
	const [fechaHasta, setFechaHasta] = useState(null);
	const [paginador, setPaginador] = useState({
		cuantos: OPCIONES_CANTIDAD_PEDIDOS[0],
		numResultados: 0,
		paginaActual: 0,
		cantidadPaginas: 1,
	});
	const [filtros, setFiltros] = useState({
		cuantos: paginador.cuantos,
		desde: 0,
		filtros: {
			finalizado: true
		}
	});
	// const [configPedidos, setConfigPedidos] = useState(null);
	useEffect(() => {
		dispatch(AppActions.setLoading(false));
		dispatch(AppActions.setActionsDisabled(false));
		document.title = CONFIG.APP_NAME + ' - Mi perfil | Historial pedidos';
		if (state.authReducer.usuario && state.authReducer.usuario.id) {
			buscarResultados(filtros);
		} else {
			const { history } = props;
			if (history) history.push('/');
		}
	}, []);
	useEffect(() => {
		// searchInput.current.focus();
	}, [pedidos]);
	// const cambiarPagina = (data) => {
	//     console.log('cambiarPagina', data);
	//     const paginaActual = data.selected;
	//     if (!requestEnProceso) {
	//         const fts = { ...filtros, desde: paginador.cuantos * (paginaActual) };
	//         setFiltros(fts);
	//         setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginaActual }));
	//         buscarResultados(fts);
	//     }
	// }

	// const cambioTextoBusqueda = (event) => {
	//     setSearchText(event.target.value);
	//     if (typingTimeout) {
	//         clearTimeout(typingTimeout);
	//     }
	//     let texto = (!event.target.value) ? "" : event.target.value;
	//     let fts = { ...filtros };
	//     fts.filtros.nombre[0] = texto;
	//     setTyping(false);
	//     setTypingTimeout(setTimeout(() => buscarResultados(fts), 1500));
	// }
	// const getConfig = () => {
	//     dispatch(AppActions.setLoading(true));
	//     dispatch(AppActions.setActionsDisabled(true));
	//     var options = {
	//         url: Api.route('a/config-pedidos'),
	//         method: 'GET'
	//     };
	//     Api.requestAxios(options, response => {
	//         Helper.log('getConfigPedidos response', response);
	//         dispatch(AppActions.setLoading(false));
	//         dispatch(AppActions.setActionsDisabled(false));
	//         if (response.data.error) {
	//             enqueueSnackbar(response.data.msg, { variant: 'error' });
	//         } else {
	//             setConfigPedidos(response.data.datos);
	//             buscarResultados(filtros);
	//         }
	//     }, error => {
	//         Helper.log('error getConfigPedidos', error);
	//         dispatch(AppActions.setLoading(false));
	//         dispatch(AppActions.setActionsDisabled(false));
	//         enqueueSnackbar('Error al traer datos de configuración', { variant: 'error' });
	//     });
	// }
	const buscarResultados = (fts) => {
		setFiltros(fts);
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		setRequestEnProceso(true);
		var options = {
			url: Api.route('u/mis-pedidos'),
			method: 'POST',
			data: fts
		};
		Api.requestAxios(options, response => {
			Helper.log('searchPedidos response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				setPaginador((pgndr) => {
					return {
						...pgndr,
						numResultados: response.data.datos.numResultados,
						cantidadPaginas: Math.ceil(response.data.datos.numResultados / fts.cuantos)
					}
				});
				setPedidos(response.data.datos.pedidos);
			}
			setRequestEnProceso(false);
		}, error => {
			Helper.log('error searchPedidos', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			setRequestEnProceso(false);
		});
	}
	const onChangeFechaDesde = (date) => {
		let fts = { ...filtros };
		if (date) {
			let desde = moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
			setFechaDesde(date);
			if (filtros.filtros.fecha) {
				fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { ...filtros.filtros.fecha, desde: desde } } };
			} else {
				fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { desde: desde } } };
			}
		} else {
			setFechaDesde(null);
			if (filtros.filtros.fecha) {
				if (filtros.filtros.fecha.hasta) {
					fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { hasta: filtros.filtros.fecha.hasta } } };
				} else {
					let filtrosFiltros = { ...filtros.filtros };
					delete filtrosFiltros.fecha;
					fts = { ...filtros, filtros: { ...filtrosFiltros } };
				}
			}
		}
		buscarResultados(fts);
	};
	const onChangeFechaHasta = (date) => {
		let fts = { ...filtros };
		if (date) {
			let hasta = moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
			setFechaHasta(date);
			if (filtros.filtros.fecha) {
				fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { ...filtros.filtros.fecha, hasta: hasta } } };
			} else {
				fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { hasta: hasta } } };
			}

		} else {
			setFechaHasta(null);
			if (filtros.filtros.fecha) {
				if (filtros.filtros.fecha.desde) {
					fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { desde: filtros.filtros.fecha.desde } } };
				} else {
					let filtrosFiltros = { ...filtros.filtros };
					delete filtrosFiltros.fecha;
					fts = { ...filtros, filtros: { ...filtrosFiltros } };
				}
			}
		}
		buscarResultados(fts);
	};
	// const slideSize = (wz) => {
	//     if (wz.width < 1180) {
	//         return 100;
	//     } else {
	//         return Math.floor((1180 * 100) / wz.width);
	//     }
	// }

	// Acciones sobre el pedido
	const handleAccionPedido = (cual, id) => {
		const { history } = props;

		switch (cual) {
			case 'ver':
				if (history) history.push('/mi-perfil/pedido?pedidoId=' + id);
				break
			case 'atras':
				break
			case 'siguiente':
				break
		}
	}

	const handleAccionPaginador = (cual, extra) => {
		let cambioFiltros = {}
		let cambiosPaginador = {}
		switch (cual) {
			case 'cambioNumResultados':
				cambioFiltros = { desde: 0, cuantos: extra }
				cambiosPaginador = { paginaActual: 0, cuantos: extra }
				break
			case 'atras':
				if (filtros.desde > 0) {
					if (!requestEnProceso) {
						cambioFiltros = { desde: paginador.cuantos * (paginador.paginaActual - 1) }
						cambiosPaginador = { paginaActual: paginador.paginaActual - 1 }
					}
				}
				break
			case 'siguiente': {
				if (filtros.desde + paginador.cuantos < paginador.numResultados) {
					if (!requestEnProceso) {
						cambioFiltros = { desde: paginador.cuantos * (paginador.paginaActual + 1) }
						cambiosPaginador = { paginaActual: paginador.paginaActual + 1 }
					}
				}
				break
			}
		}

		buscarResultados({...filtros, ...cambioFiltros})
		setPaginador(prev => ({ ...prev, ...cambiosPaginador}))
	}

	//CANCELAR
	// const cancelar = () => {
	//     setPedido(null);
	//     setPanelVer(false);
	// }

	return (
		<div id="pedidos" className="w3-block">
			<b>Pedidos</b>
			<div className="w3-row">
				<div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
					<KeyboardDatePicker
						cancelLabel="Cancelar"
						clearLabel="Limpiar"
						okLabel="Ok"
						clearable
						id="fecha-desde"
						label="Fecha desde"
						format="DD/MM/YYYY"
						value={fechaDesde}
						onChange={onChangeFechaDesde}
						KeyboardButtonProps={{
							'aria-label': 'Cambiar fecha',
						}}
					/>
				</div>
				<div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
					<KeyboardDatePicker
						cancelLabel="Cancelar"
						clearLabel="Limpiar"
						okLabel="Ok"
						clearable
						id="fecha-hasta"
						label="Fecha hasta"
						format="DD/MM/YYYY"
						value={fechaHasta}
						onChange={onChangeFechaHasta}
						KeyboardButtonProps={{
							'aria-label': 'Cambiar fecha',
						}}
					/>
				</div>
				{/* <div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
                    <FormControl variant="outlined" style={{ width: '100%', maxWidth: '420px', }}>
                        <InputLabel></InputLabel>
                        <OutlinedInputSmall
                            ref={searchInput}
                            placeholder="Buscar pedido"
                            id="input-search"
                            type="text"
                            fullWidth={true}
                            value={searchText}
                            onChange={(e) => cambioTextoBusqueda(e)}
                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                            startAdornment={
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faSearch} size="sm" color={'#546E7A'} />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div> */}
			</div>
			<ListaPedidos
				pedidos={pedidos}
				loading={requestEnProceso}
				onAccion={handleAccionPedido}
				paginador={paginador}
				numResultadosDesde={filtros.desde}
				onAccionPaginador={handleAccionPaginador}
			/>
		</div>
	);
}
export default withRouter(Pedidos);