const production = true;
const ID = 'chispa';
const APP_NAME = 'Chispa';
const GOOGLE_MAP_KEY = 'AIzaSyCaw8ahy7ztFKt6aq9kbqVlM1Bk_YmUU9s';
const GOOGLE_RECAPTCHA_SITEKEY = '6LdDhwsqAAAAAGNDXfsC6tM7-KdWTCXaTM8Ni1GT';
const DEVELOPMENT = {
    PRODUCTION: production,
    ID: ID,
    APP_NAME: APP_NAME,
    HOST: 'http://192.168.100.10/chispa/web',//localhost
    BASENAME: 'chispa/web/',//subdirectorio del host
    URL_BASE: 'http://192.168.100.10/tiendoline/server/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    LOGS: true
}
const PRODUCTION = {
    PRODUCTION: production,
    ID: ID,
    APP_NAME: APP_NAME,
    HOST: 'https://api.chispa.com.py/',//production (copiar y pegar en package.json --> homepage)
    BASENAME: '/',//subdirectorio
    URL_BASE: 'https://api.chispa.com.py/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    LOGS: false
}
export default (production) ? PRODUCTION : DEVELOPMENT;