import CONFIG from "config/environment";
import { Helper } from "utils";
console.log('APP CONFIG', CONFIG);
const AppStorage = {
    getKey:  function (name) {
        return CONFIG.ID + '-' + name;
    },
    isValidJson: function (str) {//ésta función devuelve true si str es: string-Json-válido, entero, 0, null, false
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },
    getStore: function (key) {
        if (typeof window.localStorage[key] === 'undefined') {
            return window.localStorage[key];
        } else if (this.isValidJson(window.localStorage[key])) {
            return JSON.parse(window.localStorage[key]);
        } else {
            return window.localStorage[key];
        }
    },
    setData: function (name, data) {
        let key = this.getKey(name);
        Helper.log('localStorageSave(' + key + ')', data);
        if (typeof data === 'object') {
            window.localStorage[key] = JSON.stringify(data);
        } else {
            window.localStorage[key] = data;
        }
    },
    getData: function (name) {
        let key = this.getKey(name);
        if (typeof window.localStorage[key] === 'undefined') {
            return window.localStorage[key];
        } else if (this.isValidJson(window.localStorage[key])) {
            return JSON.parse(window.localStorage[key]);
        } else {
            return window.localStorage[key];
        }
    },
    deleteData: function (name) {
        let key = this.getKey(name);
        if (window.localStorage[key]) {
            window.localStorage.removeItem(key);
        }
    },
    clear: function () {
        var toDelete = [];
        for (var i = 0; i < window.localStorage.length; i++) {
            if (window.localStorage.key(i).substring(0, CONFIG.ID.length) === CONFIG.ID) {
                toDelete.push(window.localStorage.key(i));
            }
        }
        for (var x = 0; x < toDelete.length; x++) {
            window.localStorage.removeItem(toDelete[x]);
        }
        //window.localStorage.clear();
    },
    clearAll: function () {
        window.localStorage.clear();
    }
};
export default AppStorage;