const defaultStyles = {
	fillOpacity: 0.5,
	strokeOpacity: 1,
	strokeWeight: 2,
	clickable: false,
	draggable: false,
	editable: false,
	geodesic: false,
	zIndex: 1,
}

export default function getPoligonoStyles(strokeColor = '#0373c5', fillColor = 'lightblue', overrideStyles = {}) {
	const mergedStyles = { ...defaultStyles, ...overrideStyles }
	
	return {
		...mergedStyles,
		strokeColor,
		fillColor
	}
}